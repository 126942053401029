import type { App } from "vue";

import { adminBaseUrl, v1BaseUrl } from "shared/constants";
import { createAxiosInstance } from "shared/helpers/axios";
import ApiClient from "shared/services/api/ApiClient";

const $cacheFetch = new ApiClient("/", createAxiosInstance({ baseURL: "" }));

const $streemApiV1 = new ApiClient(
  "/",
  createAxiosInstance({ baseURL: v1BaseUrl })
);

const $streemApiAdmin = new ApiClient(
  "/",
  createAxiosInstance({ baseURL: adminBaseUrl })
);

export default ({ app }: { app: App }) => {
  Object.assign(app.config.globalProperties, {
    $streemApiV1,
    $streemApiAdmin,
    $cacheFetch,
  });
};

export { $cacheFetch, $streemApiAdmin, $streemApiV1 };
