import { reverseRecord } from "shared/helpers/reverseRecord";
import type {
  FacebookSocialIntegration,
  InstagramSocialIntegration,
  LinkedinSocialIntegration,
  OrganisationSocialIntegration,
  TwitterSocialIntegration,
} from "shared/resources";

export const FACEBOOK_SDK_CONFIG = {
  appId: import.meta.env.VITE_FACEBOOK_APP_ID,
  cookie: true,
  localStorage: true,
  version: "v17.0",
  xfbml: false,
};

export const FACEBOOK_SDK_URL = "https://connect.facebook.net/en_US/sdk.js";

export enum SocialPlatform {
  FACEBOOK = "Facebook",
  INSTAGRAM = "Instagram",
  LINKEDIN = "LinkedIn",
  PINTEREST = "Pinterest",
  REDDIT = "Reddit",
  TWITTER = "Twitter",
  YOUTUBE = "YouTube",
}

export type SocialPlatformPostable =
  | "Facebook"
  | "Instagram"
  | "LinkedIn"
  | "Twitter";

export type SocialPlatformPostableIntegration =
  | FacebookSocialIntegration
  | InstagramSocialIntegration
  | LinkedinSocialIntegration
  | TwitterSocialIntegration;

export type SocialIntegration = InstanceType<
  typeof OrganisationSocialIntegration
>;

export type TokenTimestamps = Pick<
  SocialIntegration,
  "expiresAt" | "tokenRefreshedAt"
>;

export const SOCIAL_ENGAGE_PLATFORM_TOKEN_EXPIRY: Record<
  SocialPlatformPostable,
  number
> = {
  [SocialPlatform.FACEBOOK]: 5184000000, // 60 * DAY_IN_MILLISECONDS
  [SocialPlatform.INSTAGRAM]: 5184000000, // 60 * DAY_IN_MILLISECONDS
  [SocialPlatform.LINKEDIN]: 5184000000, // 60 * DAY_IN_MILLISECONDS
  [SocialPlatform.TWITTER]: Infinity, // Never expires
};

export const WIDGET_LIST = [
  { value: "stream_vs_stream", label: "Social vs Earned Media" },
  { value: "coverage_volume_breakdown", label: "Mentions By Platform" },
  { value: "coverage_volume", label: "Mentions Timeline" },
  { value: "competitor_benchmark", label: "Share Of Voice Timeline" },
  { value: "share_of_voice", label: "Share Of Voice" },
  { value: "sentiment_breakdown", label: "Sentiment Breakdown" },
  { value: "sentiment_timeline", label: "Sentiment Timeline" },
  { value: "word_cloud", label: "Themes" },
  { value: "leading_hashtags", label: "Leading Hashtags" },
  { value: "emoji_count", label: "Emoji Count" },
  { value: "top_tweets", label: "Top Tweets" },
  { value: "top_facebook_posts", label: "Top Facebook Posts" },
  { value: "top_facebook_pages", label: "Top Facebook Pages" },
  { value: "key_twitter_influencers", label: "Twitter Influencers" },
  { value: "key_instagram_influencers", label: "Instagram Influencers" },
];

export const TWO_OR_MORE_STREAM_WIDGETS = [
  "competitor_benchmark",
  "share_of_voice",
];

export const socialGraphsPalette = [
  "#FFAE6C",
  "#FF7875",
  "#F759AB",
  "#B37FEB",
  "#597EF7",
  "#69C0FF",
  "#13C2C2",
  "#7CED95",
  "#FFE58F",
  "#FFCCC7",
  "#FFADD2",
  "#D3ADF7",
  "#ADC6FF",
  "#BAE7FF",
  "#87E8DE",
  "#C3F7CF",
];

export const ENGAGE_POST_TYPES = {
  streem: {
    icon: "https://assets.streem.com.au/icons/engage-streem.svg",
    textKeypath: "social.engage_post_types.streem",
  },
  text: {
    icon: "https://assets.streem.com.au/icons/engage-text.svg",
    textKeypath: "social.engage_post_types.text",
  },
  image: {
    icon: "https://assets.streem.com.au/icons/engage-image.svg",
    textKeypath: "social.engage_post_types.image",
  },
  video: {
    icon: "https://assets.streem.com.au/icons/engage-video.svg",
    textKeypath: "social.engage_post_types.video",
  },
  reel: {
    icon: "https://assets.streem.com.au/icons/engage-reel.svg",
    textKeypath: "social.engage_post_types.reel",
  },
  shared: {
    icon: "https://assets.streem.com.au/icons/engage-shared.svg",
    textKeypath: "social.engage_post_types.shared",
  },
  link_preview: {
    icon: "https://assets.streem.com.au/icons/engage-link-preview.svg",
    textKeypath: "social.engage_post_types.link_preview",
  },
  multiple_attachments: {
    icon: "https://assets.streem.com.au/icons/engage-multiple-attachments.svg",
    textKeypath: "social.engage_post_types.multiple_attachments",
  },
  carousel: {
    icon: "https://assets.streem.com.au/icons/engage-carousel.svg",
    textKeypath: "social.engage_post_types.carousel",
  },
  job: {
    icon: "https://assets.streem.com.au/icons/engage-job.svg",
    textKeypath: "social.engage_post_types.job",
  },
  reply_tweet: {
    icon: "https://assets.streem.com.au/icons/engage-reply-tweet.svg",
    textKeypath: "social.engage_post_types.reply_tweet",
  },
  quote_tweet: {
    icon: "https://assets.streem.com.au/icons/engage-quote-tweet.svg",
    textKeypath: "social.engage_post_types.quote_tweet",
  },
};

export const PLATFORM_LABELS = {
  LinkedinSocialIntegration: SocialPlatform.LINKEDIN,
  TwitterSocialIntegration: SocialPlatform.TWITTER,
  FacebookSocialIntegration: SocialPlatform.FACEBOOK,
  InstagramSocialIntegration: SocialPlatform.INSTAGRAM,
} as const;

export const PLATFORM_LABELS_LOOKUP = reverseRecord(PLATFORM_LABELS);

export const FB_PERMISSIONS = [
  "public_profile",
  "email",
  "pages_show_list",
  "pages_read_engagement",
  "pages_manage_posts",
  "read_insights",
  "instagram_basic",
  "instagram_content_publish",
  "instagram_manage_insights",
  "business_management",
] as const;

export const CSL_PACKAGES = [
  {
    id: "CSL5Q15S",
    label: "Up to 5 searches & 1,500 sample rate per day",
  },
  {
    id: "CSL10Q15S",
    label: "Up to 10 searches & 1,500 sample rate per day",
  },
  {
    id: "CSL15Q15S",
    label: "Up to 15 searches & 1,500 sample rate per day",
  },
  {
    id: "CSL20Q15S",
    label: "Up to 20 searches & 1,500 sample rate per day",
  },
  {
    id: "CSL5Q50S",
    label: "Up to 5 searches & 5,000 sample rate per day",
  },
  {
    id: "CSL10Q50S",
    label: "Up to 10 searches & 5,000 sample rate per day",
  },
  {
    id: "CSL15Q50S",
    label: "Up to 15 searches & 5,000 sample rate per day",
  },
  {
    id: "CSL20Q50S",
    label: "Up to 20 searches & 5,000 sample rate per day",
  },
  {
    id: "CSL30Q50S",
    label: "Up to 30 searches & 5,000 sample rate per day",
  },
];

export const SOCIAL_ICONS: Record<SocialPlatform, string> = {
  [SocialPlatform.TWITTER]:
    "img:https://assets.streem.global/icons/twitter.svg",
  [SocialPlatform.FACEBOOK]:
    "img:https://assets.streem.global/icons/facebook.svg",
  [SocialPlatform.LINKEDIN]:
    "img:https://assets.streem.global/icons/linkedin.svg",
  [SocialPlatform.YOUTUBE]:
    "img:https://assets.streem.global/icons/youtube.svg",
  [SocialPlatform.INSTAGRAM]:
    "img:https://assets.streem.global/icons/instagram.svg",
  [SocialPlatform.PINTEREST]:
    "img:https://assets.streem.global/icons/pinterest.svg",
  [SocialPlatform.REDDIT]: "img:https://assets.streem.global/icons/reddit.svg",
};

export const SOCIAL_ENGAGE_POST_STATES = {
  draft: 0,
  pending_approval: 1,
  scheduled: 2,
  live: 3,
  queued: 4,
  error: 5,
  rejected: 7,
  partially_sent: 8,
} as const;

export const SOCIAL_ENGAGE_POST_STATE_LOOKUP = reverseRecord(
  SOCIAL_ENGAGE_POST_STATES
);

export type SocialEngagePostEventType =
  (typeof SOCIAL_ENGAGE_POST_EVENT_LOOKUP)[keyof typeof SOCIAL_ENGAGE_POST_EVENT_LOOKUP];

export const SOCIAL_ENGAGE_POST_EVENT_LOOKUP = {
  1: "created",
  2: "edited",
  3: "approved",
  4: "post published",
  5: "scheduled",
  6: "approval requested",
  7: "unpublish requested",
  8: "post removed",
  9: "delete requested",
  10: "deleted",
  11: "unpublish approved",
  12: "returned to draft",
  13: "rejected",
  14: "post failed",
  15: "retried",
} as const;

export const SOCIAL_ENGAGE_CHARACTER_LIMITS: Record<
  Lowercase<
    keyof Pick<
      typeof SocialPlatform,
      "FACEBOOK" | "TWITTER" | "INSTAGRAM" | "LINKEDIN"
    >
  >,
  number
> = {
  facebook: 63206,
  twitter: 280,
  instagram: 2200,
  linkedin: 3000,
};
