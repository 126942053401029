import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";
import type { Nullable } from "shared/types";

import Organisation from "./Organisation";

@Model({ apiNamespace: "/social_integrations", endpoint: "/twitter" })
class TwitterSocialIntegration extends ApplicationRecord {
  static jsonapiType = "twitter_social_integrations";

  @BelongsTo() organisation: Organisation;

  @Attr() socialPlatform: string;

  @Attr() label: string;

  @Attr() metadata: Record<string, any>;

  @Attr() token: string;

  @Attr({ persist: false }) tokenRefreshedAt: string;

  @Attr() remoteId: string;

  @Attr() organisationId: number;

  @Attr() organisationTeamIds: number[];

  @Attr() archived: boolean;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) expiresAt: Nullable<string>;

  @Attr({ persist: false }) scheduledPosts: number;

  @Attr({ persist: false }) draftPosts: number;

  @Attr() isOutreachAccount: boolean;

  platformIcon() {
    return `${import.meta.env.VITE_ASSETS_URL}/icons/twitter.svg`;
  }

  platformLabel() {
    return "Twitter";
  }
}

export default TwitterSocialIntegration;
