import { Attr, HasMany, Model } from "spraypaint";

import {
  AI_FEATURE,
  AI_PROVIDER,
  AI_PROVIDER_MODEL,
} from "shared/constants/ai";
import ContentVersion from "shared/resources/ContentVersion";
import { ApplicationRecord } from "shared/services/spraypaint";
import type { Nullable } from "shared/types/utility";

export interface AIFeatureConfigurationSafeSetting {
  category:
    | "HARM_CATEGORY_HATE_SPEECH"
    | "HARM_CATEGORY_DANGEROUS_CONTENT"
    | "HARM_CATEGORY_SEXUALLY_EXPLICIT"
    | "HARM_CATEGORY_HARASSMENT";
  threshold:
    | "OFF"
    | "BLOCK_LOW_AND_ABOVE"
    | "BLOCK_ONLY_HIGH"
    | "BLOCK_MEDIUM_AND_ABOVE";
}

export interface AIFeatureConfigurationSafeSettings {
  safety_settings: AIFeatureConfigurationSafeSetting[];
}

export interface AIFeatureConfigurationStopSequences {
  stop_sequences: string[];
}

@Model()
class AIFeatureConfiguration extends ApplicationRecord {
  static apiNamespace = "/ai";

  static jsonapiType = "feature_configurations";

  @HasMany() versions: ContentVersion[];

  @Attr() aiProvider: keyof typeof AI_PROVIDER;

  @Attr() aiProviderModel: keyof typeof AI_PROVIDER_MODEL;

  @Attr() aiRegionIds: Nullable<number[]>;

  @Attr() feature: keyof typeof AI_FEATURE;

  @Attr() featureLabel: string;

  @Attr() internalVersion: string;

  @Attr() notes: string;

  @Attr() outputFormat: "text" | "json";

  @Attr() outputFormatJson: Nullable<Record<string, unknown>>;

  @Attr() outputTokenLimit: number;

  @Attr() safetySettings: Nullable<AIFeatureConfigurationSafeSettings>;

  @Attr() stopSequences: Nullable<AIFeatureConfigurationStopSequences>;

  @Attr() systemInstructions: string;

  @Attr() temperature: number;

  @Attr() topK: number;

  @Attr() topP: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  featureName(): string {
    if (AI_FEATURE[this.feature]) {
      return AI_FEATURE[this.feature].name;
    }

    return "";
  }

  providerName(): string {
    if (AI_PROVIDER[this.aiProvider]) {
      return AI_PROVIDER[this.aiProvider].name;
    }

    return "";
  }

  providerModelName(): string {
    if (AI_PROVIDER_MODEL[this.aiProviderModel]) {
      return AI_PROVIDER_MODEL[this.aiProviderModel].name;
    }

    return "";
  }
}

export default AIFeatureConfiguration;
