import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    path: "/contact-mailing-lists",
    component: () =>
      import("pages/contactMailingLists/ContactMailingListsIndex.vue"),
    children: [
      {
        name: "contact-mailing-list-new",
        path: "new",
        component: () =>
          import("pages/contactMailingLists/ContactMailingListEdit.vue"),
        meta: {
          title: "New Contact Mailing List",
        },
      },
      {
        name: "contact-mailing-list-edit",
        path: ":id",
        props: true,
        component: () =>
          import("pages/contactMailingLists/ContactMailingListEdit.vue"),
        meta: {
          title: "Edit Contact Mailing List",
        },
      },
      {
        name: "contact-mailing-lists-index",
        path: "",
        component: () =>
          import("pages/contactMailingLists/ContactMailingLists.vue"),
        props: (route) => typecastRouteProps(route),
        meta: {
          title: "Contact Mailing Lists",
        },
      },
    ],
  },
];
