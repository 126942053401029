export default [
  {
    name: "ai",
    path: "ai",
    component: () => import("pages/ai/AIPage.vue"),
    meta: {
      title: "AI",
      accessLevel: "ai_admin",
    },
    children: [
      {
        path: "feature-configuration",
        component: () => import("pages/ai/AIFeatureConfigurationBasePage.vue"),
        meta: {
          accessLevel: "ai_admin",
          title: "AI Feature Management",
        },
        children: [
          {
            name: "ai-feature-configuration-add",
            path: "add",
            component: () =>
              import("pages/ai/AIFeatureConfigurationFormPage.vue"),
            meta: {
              title: "Add AI Feature Configuration",
              accessLevel: "ai_admin",
            },
          },
          {
            name: "ai-feature-configuration-edit",
            path: "edit/:id",
            props: true,
            component: () =>
              import("pages/ai/AIFeatureConfigurationFormPage.vue"),
            meta: {
              title: "Edit AI Feature Configuration",
              accessLevel: "ai_admin",
            },
          },
          {
            name: "ai-feature-configurations",
            path: "",
            component: () => import("pages/ai/AIFeatureConfigurationsPage.vue"),
            meta: {
              title: "AI Feature Configurations",
              accessLevel: "ai_admin",
            },
          },
        ],
      },
      {
        name: "ai-home",
        path: "",
        component: () => import("pages/ai/AIHomePage.vue"),
        meta: {
          title: "AI",
          accessLevel: "ai_admin",
        },
      },
    ],
  },
];
