export default [
  {
    name: "add-mention-stream",
    path: "/organisations/:organisationId/mention-streams/new",
    props: true,
    component: () => import("pages/mention_streams/MentionStreamsEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "New Mention Stream",
    },
  },
  {
    name: "mention-streams-edit",
    path: "/organisations/:organisationId/mention-streams/:streamId/edit",
    props: true,
    component: () => import("pages/mention_streams/MentionStreamsEditPage.vue"),
    meta: {
      accessLevel: "organisation_edit",
      title: "Edit Mention Stream",
    },
  },
  {
    name: "organisation-stream-groups",
    path: "/organisations/:organisationId/edit/stream-groups",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () =>
      import("pages/organisations/OrganisationTabStreamGroups.vue"),
    meta: {
      accessLevel: "organisation_read",
      title: "Stream Groups",
      angularName: "app.stream_groups.index",
    },
  },
];
