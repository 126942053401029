import axios, {
  type Axios,
  type AxiosInstance,
  type AxiosRequestHeaders,
} from "axios";
import axiosRetry from "axios-retry";

import random from "shared/helpers/random";

import { errorHandler } from "./errors";
import {
  authTokenRequestInterceptor,
  deserializeJsonKeyInterceptor,
  refreshTokenResponseInterceptor,
  serializeJsonKeyInterceptor,
} from "./interceptors";
import type { CreateAxiosInstance } from "./types";

declare module "axios" {
  export interface Axios {
    cacheKiller?: number;
  }

  export interface AxiosRequestConfig {
    handleErrors?: boolean;
    noAuth?: boolean;
    transformKeys?: boolean;
  }
  export interface InternalAxiosRequestConfig<D = any>
    extends AxiosRequestConfig<D> {
    headers: AxiosRequestHeaders;
  }
}

export const createAxiosInstance = ({ baseURL }: CreateAxiosInstance) => {
  const axiosInstance: Axios = axios.create({
    baseURL,
    timeout: 120000,
  });

  // Add stored token, if exists, and default errorHandler to true, to all requests.
  axiosInstance.interceptors.request.use(
    authTokenRequestInterceptor,
    errorHandler
  );

  // json key transformations
  axiosInstance.interceptors.request.use(
    serializeJsonKeyInterceptor,
    errorHandler
  );

  axiosInstance.interceptors.response.use(
    deserializeJsonKeyInterceptor,
    errorHandler
  );

  // Update refresh token if exists
  axiosInstance.interceptors.response.use(
    refreshTokenResponseInterceptor,
    errorHandler
  );

  // set a random number to use to break caches if necessary
  axiosInstance.cacheKiller = axiosInstance.cacheKiller || random();

  // retry requests when failed due to network issues
  axiosRetry(axiosInstance as AxiosInstance, { retries: 3 });

  return axiosInstance;
};
