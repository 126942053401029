import { getSources } from "shared/helpers/media";
import { useUserStore } from "shared/stores/user";

const BETA_FEATURES = [
  "has_push_to_team_notifications_toggle",
  "has_sentiment_rating_mode",
  "has_signals_notification",
  "has_most_read_sources",
  "has_microsoft_teams_integration",
];

export default {
  hasFeature: (state) => (feature) =>
    (useUserStore().betaEnabled && BETA_FEATURES.includes(feature)) ||
    feature in state.organisationFeatures,
  enabledSources() {
    return getSources().filter(
      (source) => source.filterEnabled && this.hasFeature(source.featureFlag)
    );
  },
  defaultContentToggles() {
    const defaults = {};

    this.enabledSources.forEach((source) => {
      if (this.hasFeature(source.featureFlag)) {
        defaults[`selected_${source.field}_content`] = source.relatedTypes
          .filter(
            (type) =>
              (!type.featureFlag || this.hasFeature(type.featureFlag)) &&
              !type.hidden
          )
          .map(({ field }) => field);
      }
    });

    return defaults;
  },
  defaultCheckedContentToggles() {
    const defaults = {};

    this.enabledSources.forEach((source) => {
      if (this.hasFeature(source.featureFlag)) {
        defaults[`selected_${source.field}_content`] = source.relatedTypes
          .filter(
            (type) =>
              type.checked &&
              (!type.featureFlag || this.hasFeature(type.featureFlag))
          )
          .map(({ field }) => field);
      }
    });

    return defaults;
  },
  reachedWarning: (state) => (feature) => {
    if (state.organisationFeatures[`${feature}_warning`]) {
      return (
        state.organisationFeatures[feature].credits &&
        state.organisationFeatures[feature].credits -
          state.organisationFeatures[feature].used_credits <=
          state.organisationFeatures[`${feature}_warning`].credits
      );
    }

    return false;
  },
  socialEngage: (state) => state.organisationFeatures.has_social_engage ?? null,
};
