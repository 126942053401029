import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";
import type { Nullable } from "shared/types";

import Organisation from "./Organisation";

@Model({ apiNamespace: "/social_integrations", endpoint: "/linkedin" })
class LinkedinSocialIntegration extends ApplicationRecord {
  static jsonapiType = "linkedin_social_integrations";

  @BelongsTo() organisation: Organisation;

  @Attr() socialPlatform: any;

  @Attr() label: string;

  @Attr() metadata: Record<string, any>;

  @Attr() token: string;

  @Attr({ persist: false }) tokenRefreshedAt: string;

  @Attr() remoteId: string;

  @Attr() organisationId: number;

  @Attr() organisationTeamIds: number[];

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) archived: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) expiresAt: Nullable<string>;

  @Attr({ persist: false }) scheduledPosts: number;

  @Attr({ persist: false }) draftPosts: number;

  platformIcon(whiteIcon = false) {
    const format = whiteIcon ? "15x15" : "";

    return `${import.meta.env.VITE_ASSETS_URL}/icons/linkedin${format}.svg`;
  }

  platformLabel() {
    return "LinkedIn";
  }
}

export default LinkedinSocialIntegration;
