<template>
  <div class="tw-relative">
    <div
      v-show="!isAudio && playerList.length"
      ref="videoContainer"
      class="video-container"
      :class="{ 'video-container-audio': isAudio }"
    >
      <div
        v-show="showRestrictionMessage"
        class="restriction-message"
        data-testid="restriction-message"
      >
        <p class="absolute-center text-help-text full-width q-pa-md">
          {{ $t("player_popout_interface.duration_restriction") }}
        </p>
      </div>
    </div>
    <div v-if="showPopoutInterface">
      <PlayerPopoutControls
        v-model:current-time="localCurrentTime"
        :class="{ 'player-popout-audio': isAudio }"
        :clip-list-index="clipListIndex"
        data-testid="player-popout-controls"
        :disabled="disabled"
        :first-play="firstPlay"
        :initial-clip="initialClip"
        :is-audio="isAudio"
        :is-playing="isPlaying"
        :loading="loading"
        :no-next-clip="noNextClip"
        :no-previous-clip="noPreviousClip"
        :played-clip="playedClip"
        :player="player"
        :time-zone="timeZone"
        @clear-list="$emit('clear-list')"
        @close="$emit('close')"
        @fastforward="$emit('fastforward')"
        @play-item="playItem($event)"
        @remove-item="removeItem($event)"
        @rewind="$emit('rewind')"
        @skip-next="$emit('skip-next')"
        @skip-previous="$emit('skip-previous')"
        @toggle-play="$emit('toggle-play')"
        @toggle-playlist="$emit('toggle-playlist')"
      />
    </div>
    <div v-else>
      <template v-if="isAudio">
        <PlayerPopoutAudioControls
          :current-time="localCurrentTime"
          data-testid="player-popout-audio-controls"
          :disabled="disabled"
          :first-play="firstPlay"
          :formatted-time="formatTime"
          :is-playing="isPlaying"
          :loading="loading"
          :no-next-clip="noNextClip"
          :no-previous-clip="noPreviousClip"
          @fastforward="$emit('fastforward')"
          @rewind="$emit('rewind')"
          @skip-next="$emit('skip-next')"
          @skip-previous="$emit('skip-previous')"
          @toggle-play="$emit('toggle-play')"
        >
          <QSlider
            v-model="localCurrentTime"
            class="tw--mb-2"
            :max="clipDuration"
            :min="0"
            :step="clipStep"
            style="grid-area: slider"
          />
        </PlayerPopoutAudioControls>
      </template>
      <template v-else>
        <PlayerPopoutVideoControls
          data-testid="player-popout-video-controls"
          :disabled="disabled"
          :first-play="firstPlay"
          :is-playing="isPlaying"
          :loading="loading"
          :no-next-clip="noNextClip"
          :no-previous-clip="noPreviousClip"
          @fastforward="$emit('fastforward')"
          @rewind="$emit('rewind')"
          @skip-next="$emit('skip-next')"
          @skip-previous="$emit('skip-previous')"
          @toggle-play="$emit('toggle-play')"
        >
          <PlayerProgressBar
            v-model="localCurrentTime"
            class="tw--mt-8"
            :clip-duration="clipDuration"
            :clip-step="clipStep"
            :formatted-time="formatTime"
            :min="0"
          />
        </PlayerPopoutVideoControls>
      </template>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import PlayerPopoutAudioControls from "shared/components/players/PlayerPopoutAudioControls.vue";
import PlayerPopoutControls from "shared/components/players/PlayerPopoutControls.vue";
import PlayerPopoutVideoControls from "shared/components/players/PlayerPopoutVideoControls.vue";
import PlayerProgressBar from "shared/components/players/PlayerProgressBar.vue";
import { POPOUT_PLAYER_TARGET } from "shared/constants";
import {
  dateInTimeZone,
  fromCurrentToGivenTimezone,
  getTimezone,
  shortTimezone,
} from "shared/helpers/date";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default {
  name: "PlayerPopoutInterface",
  components: {
    PlayerPopoutAudioControls,
    PlayerPopoutControls,
    PlayerPopoutVideoControls,
    PlayerProgressBar,
  },
  props: {
    clipListIndex: {
      type: Number,
      default: 0,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
    },
    firstPlay: {
      type: Boolean,
    },
    initialClip: {
      type: Object,
      default: () => ({}),
    },
    isAudio: {
      type: Boolean,
    },
    isPlaying: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    noNextClip: {
      type: Boolean,
    },
    noPreviousClip: {
      type: Boolean,
    },
    playedClip: {
      type: Object,
      default: () => ({}),
    },
    player: {
      type: HTMLVideoElement,
      required: true,
    },
    showRestrictionMessage: {
      type: Boolean,
    },
    timeZone: {
      type: String,
      default: getTimezone(),
    },
  },
  emits: [
    "clear-list",
    "close",
    "fastforward",
    "mount-video",
    "play-item",
    "remove-item",
    "rewind",
    "skip-next",
    "skip-previous",
    "toggle-play",
    "toggle-playlist",
    "update:currentTime",
  ],
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();

    const {
      playerList,
      playerPopoutTarget,
      popoutVideoRef,
      universalPlayerRef,
    } = storeToRefs(universalPlayerStore);

    return {
      playerList,
      playerPopoutTarget,
      popoutVideoRef,
      universalPlayerRef,
    };
  },
  data() {
    return {
      showPopoutInterface: false,
    };
  },
  computed: {
    clipDuration() {
      return this.playedClip.end_time
        ? this.playedClip.end_time - this.playedClip.start_time
        : 0.1;
    },
    clipStep() {
      return this.playedClip ? this.clipDuration / 100 : 0.1;
    },
    formatTime() {
      if (!this.playedClip.start_time || this.loading) return "--:--:--";

      const timestamp = Math.trunc(
        this.playedClip.start_time + this.localCurrentTime
      );

      const isoString = new Date(timestamp * 1000).toISOString();

      const timeInTimezone = fromCurrentToGivenTimezone(
        isoString,
        this.timeZone
      );

      const timezone = shortTimezone(timeInTimezone, this.timeZone);

      return `${dateInTimeZone(
        timestamp,
        "h:mm:ssaaa",
        this.timeZone
      )} ${timezone}`;
    },
    localCurrentTime: {
      get() {
        return !this.loading && this.playedClip.start_time
          ? this.currentTime
          : 0;
      },
      set(value) {
        this.player.currentTime = value;
        this.$emit("update:currentTime", value);
      },
    },
  },
  watch: {
    playerPopoutTarget: {
      immediate: true,
      handler() {
        this.showPopoutInterface =
          this.playerPopoutTarget === POPOUT_PLAYER_TARGET;
      },
    },
  },
  mounted() {
    this.$refs.videoContainer.appendChild(this.popoutVideoRef);
    this.popoutVideoRef.addEventListener("played", this.played);
    this.popoutVideoRef.addEventListener("ended", this.ended);
  },
  beforeUnmount() {
    this.popoutVideoRef.removeEventListener("played", this.played);
    this.popoutVideoRef.removeEventListener("ended", this.ended);
    this.$emit("mount-video");
  },
  methods: {
    playItem(clip) {
      this.$emit("play-item", clip);
    },
    removeItem(clip) {
      this.$emit("remove-item", clip);
    },
  },
};
</script>

<style lang="scss" scoped>
.restriction-message {
  background-color: rgb(0 0 0 / 85%);
  border-radius: 5px 5px 0 0;
  color: white;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}
</style>
