/* eslint-disable import/prefer-default-export */

import { Model, SpraypaintBase } from "spraypaint";

import { adminBaseUrl, isAdminMode, v1BaseUrl } from "shared/constants";

import { middlewareStack } from "./middleware";

@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl = isAdminMode ? adminBaseUrl : v1BaseUrl;

  static apiNamespace = "";

  async saveResource(...args: any[]): Promise<any> {
    const success = await this.save(...args);

    if (!success) {
      const message = Object.values(this.errors)
        .map((error) => `${error?.title}: ${error?.fullMessage}`)
        .join("\n");

      const error = new Error("Spraypaint Validation Error");
      error.message = message;
      throw error;
    }

    return success;
  }

  fullErrorMessages(): string {
    if (!this.errors) return "";

    return Object.values(this.errors)
      .map((error) => `${error?.title}: ${error?.fullMessage}`)
      .join("\n");
  }

  async destroyResource(): Promise<any> {
    const success = await this.destroy();

    if (!success) {
      const message = Object.values(this.errors)
        .map((error) => `${error?.title}: ${error?.fullMessage}`)
        .join("\n");

      const error = new Error("Spraypaint Validation Error");
      error.message = message;

      throw error;
    }

    return success;
  }

  dupWithDirtyTracking(): any {
    const dup = this.dup();

    // dup() on graphiti resource resets dirty tracking; which breaks saving
    // this method will retain the dirty states
    // @ts-expect-error assigning to readonly property
    dup._originalAttributes = { ...this._originalAttributes }; // eslint-disable-line no-underscore-dangle

    return dup;
  }

  resetAttribute(attribute: string) {
    // @ts-expect-error assigning to readonly property
    this._originalAttributes[attribute] = this._attributes[attribute]; // eslint-disable-line no-underscore-dangle
  }

  async saveAttributes(attributes: any, ...args: any[]) {
    const dup = this.dupWithDirtyTracking();
    dup.rollback();

    attributes.forEach((attribute: any) => {
      // @ts-expect-error any typing
      dup[attribute] = this[attribute];
    });

    await dup.saveResource(...args);

    attributes.forEach((attribute: any) => {
      // @ts-expect-error any typing
      this[attribute] = dup[attribute];
      this.resetAttribute(attribute);
    });
  }

  saveAssociation(association: any): any {
    return this.saveAttributes([association], { with: association });
  }

  markAttributeAsDirty(attribute: any) {
    // @ts-expect-error assigning to readonly property
    this._originalAttributes[attribute] = null; // eslint-disable-line no-underscore-dangle
  }
}

ApplicationRecord.middlewareStack = middlewareStack;

export { ApplicationRecord };
