import AnalyticsProxy from "@streemau/analytics-proxy";
import Logrocket from "logrocket";
import mixpanel from "mixpanel-browser";
import { storeToRefs } from "pinia";

import { debug } from "shared/constants";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

let analytics;
let initialised = false;
let identified = false;
const intercomSettings = {};

function isLoggedInViaAdmin() {
  const loginViaAdminMode = window.location.search.includes("auth_token");
  const accountManager = sessionStorage.getItem("accountManager");

  return (
    loginViaAdminMode || Boolean(accountManager && accountManager.length > 0)
  );
}

function init() {
  if (!initialised) {
    analytics.init();
    initialised = true;
  }
}

function pageView() {
  if (!isLoggedInViaAdmin() && identified) {
    analytics.pageView();
  }
}

function track(...args) {
  if (!isLoggedInViaAdmin() && identified) {
    analytics.trackEvent(...args);

    if (debug) {
      // eslint-disable-next-line no-console
      console.log("$track: ", ...args);
    }
  }
}

function identify(...args) {
  if (!isLoggedInViaAdmin()) {
    analytics.identify(...args);
    identified = true;

    if (debug) {
      // eslint-disable-next-line no-console
      console.log("$identify: ", ...args);
    }
  }
}

function stop() {
  if (!isLoggedInViaAdmin() && initialised && identified) {
    analytics.reset();
    initialised = false;
    identified = false;
  }
}

function reset() {
  stop();

  init();
}

export function updateIntercom(options = {}) {
  Object.assign(intercomSettings, options);

  if (window.Intercom) window.Intercom("update", intercomSettings);
}

function mountIntercom(appID) {
  if (!appID) return;

  const jsUrl = `https://widget.intercom.io/widget/${appID}`;

  if (window.Intercom) {
    try {
      window.Intercom("shutdown");
    } finally {
      delete window.Intercom;
    }
  }

  // Below is the original function for mounting intercom with the parts that
  // mount the script tag removed, as we mount the script tag ourselves below

  /* eslint-disable */
  (function () {
    var w = window;
    var ic = w.Intercom;
    if (typeof ic === "function") {
      ic("reattach_activator");
      ic("update", intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };
      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
    }
  })();
  /* eslint-enable */

  const script = document.querySelector('script[src*="widget.intercom.io"]');
  if (script) script.parentNode.removeChild(script);

  const newScript = document.createElement("script");
  newScript.type = "text/javascript";
  newScript.src = jsUrl;
  document.head.appendChild(newScript);
}

function newAnalyticsProxy(app, { isAdminUser = false } = {}) {
  const { $isAdminMode = false } = app.config.globalProperties;

  if (analytics) reset(); // shutdown any previous analytics

  const globalStore = useGlobalStore();
  const { appConfig } = storeToRefs(globalStore);

  const { adminUserLogrocketAppId, logrocketAppId } = appConfig.value;

  let { intercomAppId } = appConfig.value;

  let logrocketKey = isAdminUser ? adminUserLogrocketAppId : logrocketAppId;

  if (!intercomAppId) intercomAppId = null;

  let mixpanelToken = import.meta.env.VITE_MIXPANEL_TOKEN;

  if (!/^production_[a-z]{2}$/.test(import.meta.env.VITE_ENV)) {
    [mixpanelToken, intercomAppId, logrocketKey] = [];
  }

  const config = {};

  if (mixpanelToken) {
    config.mixpanel = {
      mixpanel,
      token: mixpanelToken,
    };
  }

  // eslint-disable-next-line no-param-reassign
  app.config.globalProperties.$intercomEnabled = intercomAppId && !$isAdminMode;

  if (app.config.globalProperties.$intercomEnabled) {
    mountIntercom(intercomAppId);

    config.intercom = {
      app_id: intercomAppId,
      event_allowlist: [
        "Saw Paper Article",
        "Saw Online Article",
        "Media and Transcripts clip download requested",
        "Instant Edit Download clip requested",
        "Played Clip",
        "Read Alert",
        "Create Stream Report",
        "Built Report",
        "Feature Teaser - modal opened",
        "Feature Teaser - more info clicked",
        "Feature Teaser - request access clicked",
        "Feature Teaser - request access form submitted",
        "Content Teasers - modal opened",
        "Content Teasers - more info clicked",
        "Content Teasers - request access clicked",
        "Content Teasers - request access form submitted",
        "Content Teasers - modal closed",
        "Insights Plus Teaser - modal opened",
        "Insights Plus Teaser - get in touch form shown",
        "Insights Plus Teaser - get in touch form submitted",
        "Insights Plus Teaser - modal closed",
        "Insights Plus Teaser - example report downloaded",
        "Insights Plus Teaser - fact sheet downloaded",
        "New User Requested",
        "New Brand Profile created",
        "Opened Universal Search",
      ],
    };
  }

  if (logrocketKey) {
    config.logrocket = {
      logrocket: Logrocket,
      app_id: logrocketKey,
      options: {
        dom: {
          release: "__commit_hash__",
          baseHref: "__gcp_bucket_base_href__",
        },
      },
    };
  }

  analytics = new AnalyticsProxy(config);

  if (!isLoggedInViaAdmin()) {
    init();
  }
}

export default async ({ app, router }) => {
  const { isAdminUser = false, isLoggedIn } = storeToRefs(useUserStore());
  await newAnalyticsProxy(app, { isAdminUser });

  // eslint-disable-next-line no-param-reassign
  app.config.globalProperties.$track = track;

  if (!isLoggedInViaAdmin()) {
    // Record a page view each time the url changes
    router.afterEach(() => {
      if (isLoggedIn) {
        pageView();
      }
    });
  }
};

export { identify, newAnalyticsProxy, reset, track };
