import { getLocaleText } from "shared/boot/i18n";
import { tableFilter } from "shared/helpers/object";
import { pluralize } from "shared/helpers/string";

type ArrayElement = string | number;

export function toSentence(
  array: ArrayElement[],
  separator = ", ",
  joiner = " and "
): string {
  if (array.length <= 1) return array.join();

  return `${array
    .slice(0, array.length - 1)
    .join(String(separator))}${joiner}${array.slice(-1)}`;
}

export function truncatedListSentence(
  array: ArrayElement[],
  {
    truncateAfter = 2,
    placeholder = "other",
    joiner = " + ",
  }: { truncateAfter?: number; placeholder?: string; joiner?: string } = {}
): string {
  if (array.length <= truncateAfter) {
    return toSentence(array, ", ");
  }

  const remainingLength = array.length - truncateAfter;
  const pluralizedPlaceholder = pluralize(remainingLength, placeholder);

  const list = array.slice(0, truncateAfter);
  list.push(`${remainingLength} ${pluralizedPlaceholder}`);

  return toSentence(list, ", ", joiner);
}

export const sortByStartMatches = <T>(
  array: T[],
  query: string,
  prop?: string,
  limit: number = 8
): T[] => {
  const sortFunction = prop
    ? (left: any, right: any) => (left[prop] > right[prop] ? 1 : -1)
    : undefined;

  const startRegEx = new RegExp(`^${query}`, "i");

  const startMatches = array
    .filter((entry: any) => startRegEx.test(prop ? entry[prop] : entry))
    .sort(sortFunction);

  const otherRegEx = new RegExp(`^(?!${query}).+${query}`, "i");

  const otherMatches = array
    .filter((entry: any) => otherRegEx.test(prop ? entry[prop] : entry))
    .sort(sortFunction);

  const sortedArray: T[] = startMatches.concat(otherMatches);

  return sortedArray.slice(0, limit);
};

export const filterMedia = <T, F extends object>(
  content: T[],
  filter: F
): T[] => content.filter((row) => tableFilter(row, filter));

export function formatPageNumbers(
  pageNumbers: number[],
  frontPage: boolean = false
): string {
  let result = "";

  if (pageNumbers) {
    if (pageNumbers[0] === 1 && frontPage) {
      result = getLocaleText("helpers.array.on_front_page");
    } else {
      result = getLocaleText("helpers.array.on_page_with_number", {
        count: pageNumbers.length,
        numbers: toSentence(
          pageNumbers,
          getLocaleText("global.words_separator"),
          getLocaleText("global.words_joiner")
        ),
      });
    }
  }

  return result;
}

export function partition<T>(arr: T[], splitIndex?: number): T[][] | T[] {
  if (!splitIndex) return arr;

  return [arr.slice(0, splitIndex), arr.slice(splitIndex)];
}
