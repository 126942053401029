import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";

import type { Nullable } from "shared/types/utility";

import Contact from "./Contact";
import ContactListsContact from "./ContactListsContact";
import InfluencerAddress from "./InfluencerAddress";
import InfluencerOutlet from "./InfluencersOutlet";
import InfluencersTopic from "./InfluencersTopic";
import Outlet from "./Outlet";
import OutreachTopic from "./OutreachTopic";
import TwitterUser from "./TwitterUser";
import type { Homepage, InternalId, Phone, Social } from "./types";

@Model()
class Influencer extends Contact {
  static jsonapiType = "influencers";

  @HasMany({ name: "outlets" }) outlets: Outlet[];

  @HasMany({ name: "influencerAddresses" })
  influencerAddresses: InfluencerAddress[];

  @HasMany({ name: "influencerOutlets" })
  influencersOutlets: InfluencerOutlet[];

  @HasMany({ name: "influencersTopics" })
  influencersTopics: InfluencersTopic[];

  @HasMany() influencerOutreachTopics: OutreachTopic[];

  @HasMany() contactListsContacts: ContactListsContact[];

  @BelongsTo() twitterUser: TwitterUser;

  @HasOne() outlet: Outlet;

  @Attr() salutation: string;

  @Attr() firstName: string;

  @Attr() middleName: string;

  @Attr() lastName: string;

  @Attr() suffix: string;

  @Attr() gender: string;

  @Attr() status: string;

  @Attr() optedType: string;

  @Attr() location: string;

  @Attr() biography: string;

  @Attr() profile: string;

  @Attr() verifiedDate: string;

  @Attr() verifiedBy: string;

  @Attr() homepages: Homepage[];

  @Attr() businessCardTitles: Nullable<string[]>;

  @Attr() phones: Phone[];

  @Attr() faxes: Phone[];

  @Attr() socials: Social[];

  @Attr() languages: string[];

  @Attr() internalIds: InternalId[];

  @Attr() archivedAt: string;

  @Attr() highResAvatarUrl: string;

  @Attr() twitterUsername: string;

  @Attr() primaryOutletName: string;

  @Attr() primaryOutletId: bigint;

  @Attr() averageFacebookEngagement: number;

  @Attr() averageTwitterShares: number;

  @Attr() averagePinterestShares: number;

  @Attr() averageRedditEngagements: number;

  @Attr() averageTotalEngagements: number;

  @Attr() totalLoveReactions: number;

  @Attr() totalWowReactions: number;

  @Attr() totalHahaReactions: number;

  @Attr() totalSadReactions: number;

  @Attr() totalAngryReactions: number;

  @Attr() topFacebookReaction: string;

  @Attr() dominantFacebookReaction: string;
}

export default Influencer;
