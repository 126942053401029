import { Attr, Model } from "spraypaint";

import {
  ApplicationRecord,
  objectDirtyChecker,
} from "shared/services/spraypaint";
import type { Nullable } from "shared/types";
import {
  type LaunchpadWidget,
  LaunchpadDashboardTabId,
} from "shared/types/launchpad";

@Model()
class LaunchpadSettings extends ApplicationRecord {
  static jsonapiType = "launchpad_settings";

  @Attr() userId: number;

  @Attr() organisationTeamId: number;

  @Attr() before: Nullable<number>;

  @Attr() after: Nullable<number>;

  @Attr() rangeLabel: Nullable<string>;

  @Attr() collapseSyndicated: boolean;

  @Attr({ dirtyChecker: objectDirtyChecker }) chartSettings: Record<
    string,
    any
  >;

  @Attr({ dirtyChecker: objectDirtyChecker }) widgets: Record<
    LaunchpadDashboardTabId,
    LaunchpadWidget[]
  >;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default LaunchpadSettings;
