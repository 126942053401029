import useTeamPicker from "shared/composables/useTeamPicker";
import { getCurrentTimestamp } from "shared/helpers/date";
import { MediaRequest } from "shared/resources";
import features from "shared/services/features";
import { useUserStore } from "shared/stores/user";

export default {
  fetchNewMediaRequestsCount() {
    MediaRequest.where({
      completedAt: { gt: useUserStore().currentUser.media_requests_seen_at },
      ...(features.has("has_team_workspaces")
        ? {
            organisationTeamId: [
              useTeamPicker().selectedTeamPreference.value,
              "null",
            ],
          }
        : {}),
    })
      .per(0)
      .page(1)
      .stats({ total: "count" })
      .all()
      .then((response) => {
        this.newMediaRequestsCount = response.meta.stats.total.count;
      })
      .catch(() => {
        // do nothing
      });
  },
  clearMediaItemAlertsCount() {
    this.newMediaRequestsCount = 0;

    return useUserStore().updateUser(
      { media_requests_seen_at: getCurrentTimestamp() },
      { root: true }
    );
  },
};
