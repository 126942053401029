export default [
  {
    name: "stream-group-add",
    path: "/organisations/:organisationId/stream_groups/new",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/stream_groups/StreamGroupEditPage.vue"),
    meta: {
      title: "Create Stream Group",
    },
  },
  {
    name: "stream-group-edit",
    path: "/organisations/:organisationId/stream_groups/:streamGroupId/edit",
    props: (route) => ({ ...route.params, ...route.query }),
    component: () => import("pages/stream_groups/StreamGroupEditPage.vue"),
    meta: {
      title: "Edit Stream Group",
    },
  },
];
