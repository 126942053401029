import type { AxiosRequestConfig, AxiosResponse } from "axios";
import { keyBy, sortBy } from "lodash-es";
import { defineStore } from "pinia";
import { computed, ref } from "vue";

import { track } from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";
import { getLocaleText } from "shared/boot/i18n";
import { streamTypes } from "shared/constants";
import { has } from "shared/helpers/object";
import type StreamFilters from "shared/helpers/StreamFilters";
import features from "shared/services/features";
import { useStreamGroupsStore } from "shared/stores/streamGroups";
import { useUserStore } from "shared/stores/user";
import type { Nullable, Stream, StreamGroup } from "shared/types";

type StreamGroupWithStreams = Partial<StreamGroup> &
  Pick<StreamGroup, "id"> & {
    streams: Stream[];
  };

const handleErrors = true;

function sortStreams(streams: Stream[], groups: StreamGroup[]): Stream[] {
  const keyedGroups = keyBy(groups, "id");

  return sortBy(streams, [
    (stream) => (keyedGroups[stream.group_id!]?.locked ? 0 : 1),
    (stream) => (stream.primary ? 0 : 1),
    (stream) => keyedGroups[stream.group_id!]?.position,
    (stream) => (stream.locked ? 0 : 1),
    "position",
  ]);
}

function groupStreams(
  streams: Stream[],
  groups: StreamGroup[],
  menuGroups: boolean = false
): StreamGroupWithStreams[] {
  const sortedGroups: StreamGroupWithStreams[] = groups.map((group) => {
    const groupWithStreams: StreamGroupWithStreams = {
      ...group,
      streams: [],
    };

    return groupWithStreams;
  });

  const groupsLookup: { [key: number]: number } = {};

  sortedGroups.forEach((group, index) => {
    groupsLookup[group.id!] = index;
  });

  const primaryStreamGroup: StreamGroupWithStreams = {
    id: 0,
    label: getLocaleText("streams_by_group_picker.primary_mention_stream"),
    color: "",
    streams: [],
  };

  streams.forEach((stream) => {
    if (!menuGroups && stream.primary) {
      primaryStreamGroup.streams?.push(stream);
    } else if (sortedGroups[groupsLookup[stream.group_id!]]) {
      sortedGroups[groupsLookup[stream.group_id!]].streams.push(stream);
    }
  });

  if (!menuGroups) sortedGroups.unshift(primaryStreamGroup);

  return sortedGroups;
}

export function createEmptyStream(): Stream {
  return {
    auto_refresh: false,
    can_modify: false,
    color: "",
    dashboard_enabled: false,
    dashboard_only: false,
    default_sentiment_rating: 0,
    enabled_media: [],
    group: {
      id: 0,
      position: 0,
      slug: "",
      label: "",
      color: "",
      organisation_team_id: null,
    },
    group_id: 0,
    has_streams_user: false,
    hydration_status: null,
    id: 0,
    label: "",
    locked: false,
    position: null,
    primary: false,
    refreshed_at: null,
    report_enabled: false,
    sentiment_mode_enabled: false,
    shared_with_me: false,
    shared: false,
    slug: "",
    social_bookmarks_stream: false,
    social_impact_threshold: 0,
    social_only_verified: false,
    team_ids: null,
    type: 0,
    user_id: 0,
  };
}

interface StreamFiltersState {
  [key: number]: Nullable<StreamFilters>;
}

export const useStreamsStore = defineStore("streams", () => {
  const loading = ref<boolean>(false);
  const streams = ref<Stream[]>([]);
  const filters = ref<StreamFiltersState>({});
  const streamGroupsStore = useStreamGroupsStore();
  const userStore = useUserStore();

  function getStreamsByType(type: number): Stream[] {
    return streams.value.filter((stream) => stream.type === type);
  }

  function mentionStreams(product = "media"): Stream[] {
    const mentionStreamsByType: Stream[] = getStreamsByType(
      streamTypes.mentionStream
    )
      .concat(getStreamsByType(streamTypes.organisationBrief))
      .filter((stream) => has(stream, "primary"));

    if (!features.has("has_team_workspaces")) {
      return mentionStreamsByType;
    }

    const streamGroups = keyBy(
      streamGroupsStore.mentionStreamGroups(product),
      "id"
    );

    return mentionStreamsByType.filter(
      (stream) => !stream.group_id || streamGroups[stream.group_id]
    );
  }

  const managedBriefs = computed<Stream[]>(() =>
    mentionStreams().filter((stream) => stream.locked)
  );

  const bookmarkStreams = computed<Stream[]>(() =>
    getStreamsByType(streamTypes.bookmarkStream)
  );

  const mediaBookmarkStreams = computed<Stream[]>(() => {
    if (!features.has("has_team_workspaces")) {
      return bookmarkStreams.value
        .filter((stream) => !stream.social_bookmarks_stream)
        .slice()
        .sort((streamA, streamB) => (streamA.label > streamB.label ? 1 : -1));
    }

    const currentOrganisationTeamId = userStore.preferences.selected_team_media;

    return bookmarkStreams.value
      .filter(
        (stream) =>
          !stream.social_bookmarks_stream &&
          (stream.organisation_team_id === currentOrganisationTeamId ||
            !stream.organisation_team_id)
      )
      .slice()
      .sort((streamA, streamB) => (streamA.label > streamB.label ? 1 : -1));
  });

  const socialBookmarkStreams = computed(() => {
    if (!features.has("has_team_workspaces")) {
      return bookmarkStreams.value
        .filter((stream) => stream.social_bookmarks_stream)
        .slice()
        .sort((streamA, streamB) => (streamA.label > streamB.label ? 1 : -1));
    }

    const currentOrganisationTeamId =
      userStore.preferences.selected_team_social;

    return bookmarkStreams.value
      .filter(
        (stream) =>
          stream.social_bookmarks_stream &&
          (stream.organisation_team_id === currentOrganisationTeamId ||
            !stream.organisation_team_id)
      )
      .slice()
      .sort((streamA, streamB) => (streamA.label > streamB.label ? 1 : -1));
  });

  function socialStreams(product = "social"): Stream[] {
    const socialStreamsByType: Stream[] = getStreamsByType(
      streamTypes.socialStream
    );

    if (!features.has("has_team_workspaces")) {
      return socialStreamsByType;
    }

    const streamGroups = keyBy(
      streamGroupsStore.socialStreamGroups(product),
      "id"
    );

    return socialStreamsByType.filter(
      (stream) => !stream.group_id || streamGroups[stream.group_id]
    );
  }

  const reportingStreamsDashboardEnabled = computed<Stream[]>(() => {
    const streamsByType: Stream[] = getStreamsByType(
      streamTypes.organisationBrief
    )
      .concat(getStreamsByType(streamTypes.mentionStream))
      .filter((stream) => stream.dashboard_enabled);

    const teamId = userStore.currentUser.primary_organisation_team_id;

    if (teamId) {
      return streamsByType.filter(
        (stream) => !stream.team_ids || stream.team_ids.includes(teamId)
      );
    }

    return streamsByType;
  });

  const dashboardEnabledUserAccessibleStreams = computed(() => {
    const user = userStore.currentUser;

    const filteredStreams = mentionStreams().filter(
      (stream) => stream.dashboard_enabled
    );

    if (user.primary_organisation_team_id) {
      const teamId = user.primary_organisation_team_id;

      return filteredStreams.filter(
        (stream) => !stream.team_ids || stream.team_ids.includes(teamId)
      );
    }

    return filteredStreams;
  });

  const reportingStreamsReportEnabled = computed(() => {
    const streamsByType = getStreamsByType(streamTypes.organisationBrief)
      .concat(getStreamsByType(streamTypes.mentionStream))
      .filter((stream) => stream.report_enabled);

    const sortedStreams = streamsByType.sort(
      (streamA, streamB) =>
        streamA.group.position - streamB.group.position ||
        Number(streamA.position) - Number(streamB.position)
    );

    const teamId = userStore.currentUser.primary_organisation_team_id;

    if (teamId) {
      return sortedStreams.filter(
        (stream) => !stream.team_ids || stream.team_ids.includes(teamId)
      );
    }

    return sortedStreams;
  });

  const primaryStreams = computed(() =>
    mentionStreams()
      .concat(getStreamsByType(streamTypes.bookmarkStream))
      .filter((stream) => stream.primary)
  );

  const sharedMentionStreams = computed(() =>
    mentionStreams().filter(
      (stream) =>
        stream.shared &&
        !stream.primary &&
        stream.user_id !== userStore.currentUser.id
    )
  );

  const sharedSocialStreams = computed(() =>
    socialStreams().filter(
      (stream) =>
        stream.shared &&
        !stream.primary &&
        stream.user_id !== userStore.currentUser.id
    )
  );

  const sortedTraditionalStreams = computed(() => {
    const sortedMentionStreams = sortStreams(
      mentionStreams(),
      streamGroupsStore.mentionStreamGroups()
    );

    return [...sortedMentionStreams, ...mediaBookmarkStreams.value];
  });

  const sortedSocialStreams = computed(() => {
    const sortedMentionStreams = sortStreams(
      socialStreams(),
      streamGroupsStore.socialStreamGroups()
    );

    return [...sortedMentionStreams, ...socialBookmarkStreams.value];
  });

  const defaultStream = computed<Nullable<Stream>>(
    () =>
      primaryStreams.value.at(0) ||
      sortedTraditionalStreams.value.find((streamItem) => !streamItem.locked) ||
      managedBriefs.value.at(0) ||
      null
  );

  function getVisibleStreamById(id: number) {
    return [
      ...sortedTraditionalStreams.value,
      ...sortedSocialStreams.value,
    ].find((stream) => stream.id === id);
  }

  function getStreamById(id: number): Stream | undefined {
    return streams.value.find((stream) => stream.id === id);
  }

  function getStreamBySlug(slug: string) {
    return streams.value.find((stream) => stream.slug === slug);
  }

  function getStreamsByGroupId(groupId: number): Stream[] {
    return streams.value.filter(
      (stream) => stream.group_id === groupId && !stream.primary
    );
  }

  function groupedSocialBookmarkStreams(): StreamGroupWithStreams[] {
    return groupStreams(
      sortedSocialStreams.value,
      streamGroupsStore.getStreamGroupsByStreamType(
        streamTypes.bookmarkStream,
        "social"
      ),
      false
    );
  }

  function groupedMentionStreams({
    menuGroups = false,
  } = {}): StreamGroupWithStreams[] {
    return groupStreams(
      getStreamsByType(streamTypes.mentionStream).concat(
        getStreamsByType(streamTypes.organisationBrief)
      ),
      streamGroupsStore.mentionStreamGroups(),
      menuGroups
    ).filter((group) => group.streams.length);
  }

  function groupedTraditionalStreams({
    menuGroups = false,
    product = "media",
  } = {}): StreamGroupWithStreams[] {
    return groupStreams(
      sortedTraditionalStreams.value,
      streamGroupsStore.mentionStreamGroups(product),
      menuGroups
    );
  }

  function groupedBookmarkStreams({
    menuGroups = false,
  } = {}): StreamGroupWithStreams[] {
    return groupStreams(
      getStreamsByType(streamTypes.bookmarkStream),
      streamGroupsStore.mentionStreamGroups(),
      menuGroups
    ).filter(
      (group) =>
        group.streams.length && group.stream_type === streamTypes.bookmarkStream
    );
  }

  function groupedSocialStreams({
    menuGroups = false,
  } = {}): StreamGroupWithStreams[] {
    return groupStreams(
      sortedSocialStreams.value,
      streamGroupsStore.socialStreamGroups(),
      menuGroups
    );
  }

  function getStreamFilters(streamId: number) {
    return filters.value[streamId];
  }

  const mediaTeamMentionStreamsCount = computed<number>(() => {
    const currentOrganisationTeamId = userStore.preferences.selected_team_media;

    const bookmarkStreamsCount = bookmarkStreams.value.filter(
      (stream) =>
        !stream.social_bookmarks_stream &&
        stream.organisation_team_id === currentOrganisationTeamId
    ).length;

    const streamGroups = keyBy(
      streamGroupsStore.mediaTeamMentionStreamGroups,
      "id"
    );

    const mentionStreamsCount = getStreamsByType(
      streamTypes.mentionStream
    ).filter((stream) => streamGroups[stream.group_id]).length;

    return mentionStreamsCount + bookmarkStreamsCount;
  });

  function setStream({ id, data }: { id: number; data: Stream }): void {
    streams.value = streams.value.map((stream) =>
      stream.id === id ? { ...data } : stream
    );
  }

  function putStreams(streamsToUpdate: Partial<Stream>[]): void {
    const hash: { [key: number | string]: Stream } = {};

    streams.value.forEach((stream) => {
      hash[stream.id] = stream;
    });

    streamsToUpdate.forEach((stream) => {
      if (stream.id) Object.assign(hash[stream.id], stream);
    });

    streams.value = Object.values(hash);
  }

  function putStream({ id, data }: { id: number; data: Partial<Stream> }) {
    streams.value = streams.value.map((stream) =>
      stream.id === id ? { ...stream, ...data } : stream
    );
  }

  function pushStream(data: Stream): void {
    streams.value = [...streams.value, data];
  }

  function filterStream({ id }: { id: number }): void {
    streams.value = [...streams.value.filter((stream) => stream.id !== id)];
  }

  function setStreamFilters({
    streamId,
    filters: streamFilters,
  }: {
    streamId: number;
    filters: Nullable<StreamFilters>;
  }): void {
    filters.value[streamId] = streamFilters;
  }

  function getStreams(): Promise<void> {
    return $streemApiV1.get("streams", { handleErrors }).then((response) => {
      streams.value = response.data;
    });
  }

  function getStream(
    id: number,
    options: AxiosRequestConfig = {}
  ): Promise<AxiosResponse> {
    return $streemApiV1
      .get(`streams/${id}`, { handleErrors, ...options })
      .then((response) => {
        setStream({ id, data: response.data });

        return response;
      });
  }

  async function fetchStreamById<T = Stream>(
    id: number,
    options: AxiosRequestConfig = {}
  ): Promise<T> {
    const response = await getStream(id, options);

    return response.data as T;
  }

  function replaceStreams(data: Stream[]): void {
    const hash: { [key: number | string]: Stream } = {};

    streams.value.forEach((stream) => {
      hash[stream.id] = stream;
    });

    data.forEach((stream) => {
      hash[stream.id] = stream;
    });

    streams.value = Object.values(hash);
  }

  function updateStream({
    id,
    params,
  }: {
    id: number;
    params: Partial<Stream>;
  }): Promise<AxiosResponse> {
    track("Updated Stream");

    return $streemApiV1
      .put(`streams/${id}`, { params, handleErrors })
      .then((response) => {
        setStream({ id, data: response.data });

        return response;
      });
  }

  // If the position of some stream have changed, then all the other
  // stream positions in the same group too, that's why the getStreams
  function updateStreamPosition({
    id,
    newPosition,
    newUserPosition,
  }: {
    id: number;
    newPosition: number;
    newUserPosition: number;
  }): Promise<void> {
    track("Updated Stream position");

    return $streemApiV1
      .put(`streams/${id}`, {
        params: {
          position: newPosition,
          user_position: newUserPosition,
        },
        handleErrors,
      })
      .then(() => {
        getStreams();
      });
  }

  function updateStreamFilters({
    id,
    filters: streamFilters,
  }: {
    id: number;
    filters: unknown;
  }): Promise<void> {
    track("Set Stream Filters");

    return $streemApiV1
      .post(`streams/${id}/filters`, {
        params: { filters: streamFilters },
        handleErrors,
      })
      .then((response) => setStream({ id, data: response.data }));
  }

  function addStream({
    params,
  }: {
    params: Partial<Stream>;
  }): Promise<AxiosResponse> {
    track("Created Stream");

    return $streemApiV1
      .post("streams", { params, handleErrors })
      .then((response) => {
        pushStream(response.data);

        return response;
      });
  }

  function removeStream({ id }: { id: number }): Promise<void> {
    track("Deleted Stream");

    return $streemApiV1.delete(`streams/${id}`, { handleErrors }).then(() => {
      filterStream({ id });
    });
  }

  function archiveStream({ id }: { id: number }): Promise<void> {
    track("Archived Stream");

    return $streemApiV1
      .put(`streams/${id}/archive`, { handleErrors })
      .then(() => {
        filterStream({ id });
      });
  }

  return {
    addStream,
    archiveStream,
    bookmarkStreams,
    dashboardEnabledUserAccessibleStreams,
    defaultStream,
    fetchStreamById,
    filters,
    filterStream,
    getStream,
    getStreamById,
    getStreamBySlug,
    getStreamFilters,
    getStreams,
    getStreamsByGroupId,
    getStreamsByType,
    getVisibleStreamById,
    groupedBookmarkStreams,
    groupedMentionStreams,
    groupedSocialBookmarkStreams,
    groupedSocialStreams,
    groupedTraditionalStreams,
    loading,
    managedBriefs,
    mediaBookmarkStreams,
    mediaTeamMentionStreamsCount,
    mentionStreams,
    primaryStreams,
    pushStream,
    putStream,
    putStreams,
    removeStream,
    replaceStreams,
    reportingStreamsDashboardEnabled,
    reportingStreamsReportEnabled,
    setStream,
    setStreamFilters,
    sharedMentionStreams,
    sharedSocialStreams,
    socialBookmarkStreams,
    socialStreams,
    sortedSocialStreams,
    sortedTraditionalStreams,
    streams,
    updateStream,
    updateStreamFilters,
    updateStreamPosition,
  };
});

export default useStreamsStore;
