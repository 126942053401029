<template>
  <div
    class="tw-grid tw-w-full tw-grid-cols-2 tw-grid-rows-2 tw-items-center tw-text-white"
  >
    <time class="tw-text-xs tw-drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]">{{
      currentTime
    }}</time>
    <time
      class="tw-text-right tw-text-xs tw-drop-shadow-[0_1px_1px_rgba(0,0,0,0.8)]"
      >{{ formattedTime }}</time
    >
    <QSlider
      class="tw-col-span-2"
      color="white"
      data-testid="player-progress-bar"
      dense
      inner-track-color="grey-5"
      :max="clipDuration"
      :min="0"
      :model-value="modelValue"
      :step="clipStep"
      track-color="grey-5"
      @pan="handlePan"
      @update:model-value="debouncedModelValueUpdate"
    />
  </div>
</template>

<script lang="ts">
export type PlayerProgressBarProps = {
  clipDuration: number;
  clipStep: number;
  formattedTime: string;
  modelValue: number;
};
</script>

<script lang="ts" setup>
import { debounce } from "lodash-es";
import { QSlider } from "quasar";
import { computed, ref } from "vue";

import { secondsToTimecodeWithSeconds } from "shared/helpers/date";
import useUniversalPlayerStore from "shared/stores/universalPlayer";

const universalPlayerStore = useUniversalPlayerStore();

const props = defineProps<PlayerProgressBarProps>();
const emit = defineEmits(["update:modelValue"]);
const isPanning = ref(false);

const currentTime = computed(() =>
  secondsToTimecodeWithSeconds(props.modelValue)
);

const handleModelValueUpdate = (value: null | number): void => {
  if (value === null) return;
  emit("update:modelValue", value);
};

const handlePan = () => {
  isPanning.value = !isPanning.value;
  universalPlayerStore.setPlayerIsBeingScrubbed(isPanning.value);
};

const debouncedModelValueUpdate = debounce(handleModelValueUpdate, 10);
</script>
