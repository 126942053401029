import type {
  InstantInsightsChart,
  SocialAnalyseChart,
} from "shared/resources/types";

import cisionConfig from "./cision.json";
import cisionCAConfig from "./cision_ca.json";
import cisionFRConfig from "./cision_fr.json";
import cisionUSConfig from "./cision_us.json";
import streemConfig from "./streem.json";
import { type AppConfig, type CisionSpecificConfig, Region } from "./types";

const CONFIG_BY_REGION: { [key in Region]?: CisionSpecificConfig } = {
  [Region.CA]: cisionCAConfig,
  [Region.FR]: cisionFRConfig,
  [Region.US]: cisionUSConfig,
};

function buildConfig(
  config: typeof cisionConfig | typeof streemConfig
): AppConfig {
  return {
    ...config,
    instantInsightsCharts:
      config.instantInsightsCharts as InstantInsightsChart[],
    socialAnalyseCharts: config.socialAnalyseCharts as SocialAnalyseChart[],
  };
}

export default {
  getConfig(region: Region) {
    let config: AppConfig;

    if (region === Region.AU) {
      config = buildConfig(streemConfig);
    } else {
      config = buildConfig(cisionConfig);
    }

    const specificConfig = CONFIG_BY_REGION[region];

    if (specificConfig) {
      config = {
        ...config,
        ...specificConfig,
      };
    }

    return config;
  },
};
