import type LocalForage from "localforage";

import store from "shared/services/localForageInstance";

const MAX_EXPIRING_ENTRIES = 200;

let pruneKeysTimeout: ReturnType<typeof setTimeout> | null = null;

const StorageService = {
  async get(key: string, strategy = store): Promise<string | null> {
    return Promise.resolve(
      sessionStorage.getItem(key) || (await strategy.getItem(key))
    );
  },
  set(key: string, value: any, strategy: LocalForage | Storage = store) {
    return strategy.setItem(key, value);
  },
  remove(key: string, strategy: LocalForage | Storage = store) {
    return strategy.removeItem(key);
  },
  setEx(key: string, value: any, ttl: number) {
    const data = {
      expireAfter: Date.now() + ttl,
      value,
    };

    return this.set(key, JSON.stringify(data), store);
  },
  expireKeys() {
    const removalPromises: Array<void | Promise<void>> = [];

    store.iterate((value: any, key: string) => {
      try {
        const expiry = JSON.parse(value)?.expireAfter;

        if (expiry && expiry < Date.now()) {
          removalPromises.push(this.remove(key, store));
        }
      } catch {
        // do nothing
      }
    });

    return Promise.allSettled(removalPromises);
  },
  async pruneKeys() {
    await this.expireKeys();

    const entries: Array<{ key: string; expiry: number }> = [];

    await store.iterate((value: any, key: string) => {
      try {
        const expiry: number = JSON.parse(value)?.expireAfter;

        if (expiry) entries.push({ key, expiry });
      } catch {
        // do nothing
      }
    });

    if (entries.length <= MAX_EXPIRING_ENTRIES) return Promise.resolve();

    const newestEntries = entries.sort(
      (entryA, entryB) => entryB.expiry - entryA.expiry
    );

    const oldestEntries = newestEntries.slice(MAX_EXPIRING_ENTRIES);

    return Promise.allSettled(
      oldestEntries.map((entry) => this.remove(entry.key, store))
    );
  },
  debouncedPruneKeys() {
    if (pruneKeysTimeout !== null) {
      clearTimeout(pruneKeysTimeout);
    }

    pruneKeysTimeout = setTimeout(() => this.pruneKeys(), 1000);
  },
};

export default StorageService;
