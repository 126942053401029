import type { MediumName } from "shared/helpers/media";
import type { Nullable, SentimentRating } from "shared/types";

export enum MentionType {
  article = "article",
  paper_article = "paper_article",
  magazine_article = "magazine_article",
  tv_caption = "tv_caption",
  tv_super = "tv_super",
  tv_logo_appearance = "tv_logo_appearance",
  radio_clip = "radio_clip",
  tweet = "tweet",
  facebook_post = "facebook_post",
  youtube_video = "youtube_video",
  instagram_post = "instagram_post",
  reddit_post = "reddit_post",
  reddit_post_comment = "reddit_post_comment",
  blog_post = "blog_post",
  forum_post = "forum_post",
  podcast_episode = "podcast_episode",
  external_item = "external_item",
  customer_article = "customer_article",
}

export enum SocialMentionType {
  tweet = MentionType.tweet,
  facebook_post = MentionType.facebook_post,
  youtube_video = MentionType.youtube_video,
  instagram_post = MentionType.instagram_post,
  reddit_post = MentionType.reddit_post,
  reddit_post_comment = MentionType.reddit_post_comment,
  blog_post = MentionType.blog_post,
  forum_post = MentionType.forum_post,
}

export enum BroadcastMentionType {
  radio_clip = MentionType.radio_clip,
  tv_caption = MentionType.tv_caption,
  tv_logo_appearance = MentionType.tv_logo_appearance,
  tv_super = MentionType.tv_super,
}

export type SyndicationKey = `${MentionType}_${number}`;

export type Author =
  | {
      name: string;
    }
  | string;

export type Sentiment = number | string | null;

export type Keywords = {
  [key: string]: number;
};

export type Excerpts = {
  keywords: string[];
  text: string;
};

export type SourceGroup = {
  id: number;
  name: string;
  logo_url: string;
};

export type Source = {
  clips_gap_threshold_seconds?: number;
  domain_authority?: number;
  group?: string;
  id: number;
  location: string;
  logo_url: string;
  name: string;
  origin: string;
  satirical?: boolean;
  source_group?: SourceGroup;
  tier?: number;
  time_zone: string;
};

export type ImpactScore = {
  score: number;
  grade: "Low" | "Medium" | "High";
};

export type Bookmark = {
  id: number;
  stream_id: number;
  organisation_team_id?: number;
  label: string;
  color: string;
  social_bookmarks_stream: boolean;
};

export type FactmataEnrichment = {
  risk_score: string;
  aggregate_harmful: string;
  aggregate_fakeness: string;
  aggregate_spam: string;
  aggregate_emotionality: string;
};

export type Activity = {
  feature_id: number;
  feature_name: string;
  user_id: number;
  created_at: string;
};

export interface BaseMention {
  activities?: Activity[];
  advertising_value?: Nullable<number>;
  audience: number;
  bookmarks?: Bookmark[];
  created_at: string;
  excerpt?: string;
  excerpts?: Excerpts[];
  factmata_enrichment?: FactmataEnrichment;
  id: number;
  impact_score: ImpactScore;
  keywords?: Keywords;
  language_code: Nullable<string>;
  medium: MediumName;
  published_at: string;
  removed?: boolean;
  sentiment: Sentiment;
  sentiment_ratings?: SentimentRating[];
  source: Source;
  syndication_key: SyndicationKey;
  time_bundle_key: Nullable<string>;
  timestamp: number;
  timestamp_milliseconds: number;
  type: MentionType;
  ucp_id?: Nullable<string>;
}

export interface Article extends BaseMention {
  authenticated_url: string;
  author: Author;
  internal_link: string;
  public_link: string;
  preview_url: string;
  syndications: ArticleSyndication[];
  title: string;
  url: string;
  full_body?: string;
  word_count: number;
  international_plus: boolean;
  license: Nullable<string>;
  requires_authenticated_url: boolean;
  translated_title?: string;
  translated_body?: string;
  body?: string;
  original_language?: string;
  original_url?: string;
  og_description: Nullable<string>;
  og_image: Nullable<string>;
  related_article?: PaperArticle;
  in_app_redirect: boolean;
  outside_source_retention_period: boolean;
  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
  desktop_monthly_visits?: number;
  mobile_monthly_visits?: number;
}

export interface ArticleSyndication extends Omit<Article, "syndications"> {
  source_name: string;
}

export interface PaperArticle extends BaseMention {
  title: string;
  page_numbers: string;
  pdf_url?: string;
  public_url: string;
  authenticated_url: string;
  thumbnail_url: string;
  size: number;
  section: string;
  license: Nullable<string>;
  requires_authenticated_url: boolean;
  requires_content_warning: boolean;
  requires_allowance_check: boolean;
  datahub: boolean;
  translated_title?: string;
  translated_body?: string;
  body?: string;
  original_language?: string;
  word_count: number;
  author: Author;
  syndications: PaperArticleSyndication[];
  related_article?: Article;
  in_app_redirect: boolean;
  permission?: boolean;
  url?: boolean;
  expired_article: boolean;
  outside_source_retention_period: boolean;
  mediatrack_international_print: boolean;
  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
  desktop_monthly_visits?: number;
  mobile_monthly_visits?: number;
}

export interface PaperArticleSyndication extends Omit<Article, "syndications"> {
  source_name: string;
}

export interface MagazineArticle extends BaseMention {
  title: string;
  page_numbers: string;
  pdf_url?: string;
  authenticated_url: string;
  thumbnail_url: string;
  size: number;
  section: string;
  license: Nullable<string>;
  requires_authenticated_url: boolean;
  requires_allowance_check: boolean;
  word_count: number;
  author: Author;
  in_app_redirect: boolean;
  expired_article: boolean;
  outside_source_retention_period: boolean;
  mediatrack_international_print: boolean;
  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
  related_article?: Article;
}

export type TvFragment = {
  id: number;
  timestamp: number;
  text: string;
};

export type ProgramAiring = {
  id: number;
  name: string;
  current_affairs: boolean;
};

export type TvProgram = {
  id: number;
  name: string;
};

export type MediaRequestStatus =
  | "Incomplete"
  | "In Progress"
  | "Completed"
  | "Cancelled";

export type MediaRequest = {
  id: number;
  media_url: string;
  status: MediaRequestStatus;
};

export type TvThumbnail = {
  timestamp: number;
  channel_id: number;
  url: string;
  created_at: string;
};

export interface TvCaption extends BaseMention {
  start_time: number;
  end_time: number;
  text?: string;
  program_exclusion: boolean;
  datahub: boolean;
  fragments: TvFragment[];
  tv_super_id?: number;
  tv_super_fragments?: TvFragment[];
  program_airing?: ProgramAiring;
  program?: TvProgram;
  thumbnails?: TvThumbnail[];
  tveyes_player_url: Nullable<string>;
  tveyes_media_request?: MediaRequest;
  syndications: TvCaptionSyndication[];
  location?: string;

  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export interface TvClip extends BaseMention {
  channel_id: number;
  media_url: string;
  start_time: number;
  end_time: number;
  program_airing?: ProgramAiring;
  program?: TvProgram;
  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export interface TvCaptionSyndication extends Omit<TvCaption, "syndications"> {
  source_name: string;
  channel_id: number;
  source_id: number;
}

export interface TvSuper extends BaseMention {
  start_time: number;
  end_time: number;
  internal_link: string;
  text?: string;
  program_exclusion: boolean;
  fragments: TvFragment[];
  program?: TvProgram;
  program_airing?: ProgramAiring;
  thumbnails?: TvThumbnail[];
  syndications: TvSuperSyndication[];
  location?: string;
  matches_stream?: boolean;
}

export interface TvSuperSyndication extends Omit<TvCaption, "syndications"> {
  source_name: string;
  channel_id: number;
  source_id: number;
}

export interface TvLogoAppearance extends BaseMention {
  start_time: number;
  end_time: number;
  internal_link: string;
  text?: string;
  program?: TvProgram;
  program_airing?: ProgramAiring;
  thumbnails?: TvThumbnail[];
  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export interface RadioClip extends BaseMention {
  start_time: number;
  end_time: number;
  media_url: number;
  internal_link: string;
  text?: string;
  datahub: boolean;
  program_airing: ProgramAiring;
  original: {
    station_id: number;
    start_time: number;
  };
  tveyes_player_url: Nullable<string>;
  tveyes_media_request?: MediaRequest;
  syndications: RadioClipSyndication[];
  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export interface RadioClipSyndication extends Omit<RadioClip, "syndications"> {
  source_name: string;
  source_id: number;
  station_id: number;
  location?: string;
  is_original?: boolean;
}

export enum TweetType {
  original = 0,
  retweet = 1,
  reply = 2,
  quote = 3,
}

export type TweetAsset = {
  id: number;
  media_url: string;
  url: string;
  media_type: string;
  duration_in_milliseconds: number;
};

export interface Tweet extends BaseMention {
  tweet_type: TweetType;
  url: string;
  body: string;
  body_highlighted?: string;
  author?: Author;
  handle?: string;
  username?: string;
  twitter_handle?: string;
  twitter_username?: string;
  author_avatar_image?: string;
  profile_description?: string;
  author_country?: string;
  author_state?: string;
  author_city?: string;
  author_latitude?: number;
  author_longitude?: number;
  author_follower_count?: number;
  author_gender?: string;
  author_verified?: boolean;
  tweet_assets: TweetAsset[];
  impact: number;
  hashtags: string[];
  user_mentions: string[];
  retweet_count: number;
  favorite_count: number;
  reply_count: number;
  quote_count: number;
  public_link: string;
  status_url: string;
  liked_by_url: string;
  retweeted_by_url: string;
  quoted_by_url: string;
  original_tweet?: Tweet;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export type FacebookPostAsset = {
  id: number;
  image_url: string;
  media_type: string;
};

export type FacebookPage = {
  id: number;
  url: string;
};

export interface FacebookPost extends BaseMention {
  url: string;
  body: string;
  internal_link: string;
  links: string[];
  impact: number;
  like_count: number;
  comment_count: number;
  share_count: number;
  author_verified: boolean;
  author_follower_count: Nullable<number>;
  facebook_post_assets: FacebookPostAsset[];
  facebook_page?: FacebookPage;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export interface YoutubeVideo extends BaseMention {
  youtube_id: string;
  title: string;
  thumbnail_url: string;
  url: string;
  embedded_player: string;
  view_count: number;
  comment_cont: number;
  like_count: number;
  dislike_count: number;
  duration: number;
  author_follower_count: number;
  public_link: string;
  internal_link: string;
  impact?: number;
  score?: number;
  source_follower_count?: number;
}

export type InstagramPostAsset = {
  id: number;
  media_type: string;
  image_url: string;
};

export interface InstagramPost extends BaseMention {
  thumbnail_url: string;
  internal_link: string;
  url: string;
  author_verified: boolean;
  author_follower_count: number;
  author_city?: string;
  handle: string;
  image: string;
  video_url: string;
  public_link: string;
  instagram_post_assets: InstagramPostAsset[];
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export type RedditPostAsset = {
  id: number;
  image_url: string;
};

export interface RedditPost extends BaseMention {
  url: string;
  title: string;
  summary: string;
  source_name: string;
  source_follower_count: number;
  likes_count: number;
  comments_count: number;
  crossposts_count: number;
  internal_link: string;
  score: number;
  over_18: boolean;
  has_body: boolean;
  public_link: string;
  reddit_post_assets: RedditPostAsset[];
  body_highlighted?: string;
  author_follower_count?: number;
  impact?: number;
  view_count?: number;
}

export interface RedditPostComment extends BaseMention {
  url: string;
  summary: string;
  source_name: string;
  soruce_follower_count: number;
  likes_count: number;
  comments_count: number;
  internal_link: string;
  score: number;
  over_18: boolean;
  public_link: string;
  body_highlighted?: string;
  reddit_post?: RedditPost;
  author_follower_count?: number;
  impact?: number;
  source_follower_count?: number;
  view_count?: number;
}

export interface BlogPost extends BaseMention {
  url: string;
  title: string;
  word_count?: number;
  internal_link: string;
  public_link: string;
  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export interface ForumPost extends BaseMention {
  url: string;
  title: string;
  word_count?: number;
  internal_link: string;
  public_link: string;
  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export interface PodcastEpisode extends BaseMention {
  title: string;
  url: string;
  internal_link: string;
  public_link: string;
  tveyes_player_url: string;
  author_follower_count?: number;
  impact?: number;
  score?: number;
  source_follower_count?: number;
  view_count?: number;
}

export interface CustomerArticle
  extends Pick<
    BaseMention,
    "id" | "created_at" | "published_at" | "excerpts" | "type"
  > {
  url: string;
  title: string;
  text: string;
  body: string;
  keyword: string;
  word_count: number;
  source: string;
  medium: string;
}

export interface ExternalItem
  extends Omit<
    BaseMention,
    | "excerpt"
    | "removed"
    | "bookmarks"
    | "activities"
    | "factmata_enrichment"
    | "source"
  > {
  title: string;
  summary: string;
  created_by: string;
  public_link: string;
  source: Pick<Source, "name" | "time_zone">;
  factmata_enrichment?: FactmataEnrichment;
  author_follower_count?: number;
  impact?: number;
  view_count?: number;
  score?: number;
  source_follower_count?: number;
}

export type MentionWithSyndication =
  | Article
  | PaperArticle
  | TvCaption
  | TvSuper
  | RadioClip;

export type Mention =
  | Article
  | PaperArticle
  | MagazineArticle
  | RadioClip
  | TvCaption
  | TvCaptionSyndication
  | TvClip
  | TvLogoAppearance
  | RadioClipSyndication
  | PodcastEpisode
  | Tweet
  | FacebookPost
  | YoutubeVideo
  | InstagramPost
  | RedditPost
  | RedditPostComment
  | BlogPost
  | ForumPost
  | ExternalItem;

export type Syndication =
  | ArticleSyndication
  | TvCaptionSyndication
  | RadioClipSyndication
  | TvSuperSyndication;

export type MentionOrCustomerArticle = Mention | CustomerArticle;
