import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";

import { insightsQueryProcessingStatuses } from "shared/constants";
import { ApplicationRecord } from "shared/services/spraypaint";

import Article from "./Article";
import InsightsKeySpokesperson from "./InsightsKeySpokesperson";
import InsightsKeyTopic from "./InsightsKeyTopic";
import InsightsQueryFilter from "./InsightsQueryFilter";
import InsightsReport from "./InsightsReport";
import Organisation from "./Organisation";
import RadioClip from "./RadioClip";
import ReportFile from "./ReportFile";

type InsightQueryStatus =
  | "export_pending"
  | "export_in_progress"
  | "export_completed"
  | "sentiment_rating_pending"
  | "sentiment_rating_in_progress"
  | "sentiment_rating_completed"
  | "error";

type InsightQueryType = "quantitative" | "qualitative" | "insights_plus";

@Model()
class InsightsQuery extends ApplicationRecord {
  static jsonapiType = "insights_queries";

  @BelongsTo() parent: InsightsReport | Organisation;

  @HasOne() reportFile: ReportFile;

  @HasMany() reportFiles: ReportFile[];

  @HasMany() keyTopics: InsightsKeyTopic[];

  @Attr() archivedAt: string;

  @Attr() booleanQuery: string;

  @Attr() caseSensitive: boolean;

  @Attr() category: string;

  @Attr() color: string;

  @Attr() status: InsightQueryStatus;

  @Attr() excludedLocationKeys: string[];

  @Attr() excludedCategoryKeys: string[];

  @Attr() filterSetIds: number[];

  @Attr() label: string;

  @Attr() notes: string;

  @Attr() parentId: number;

  @Attr() parentType: string;

  @Attr() topicQuery: boolean;

  @Attr() subTopics: string[];

  @Attr() mainQuery: boolean;

  @Attr() onlineContent: boolean;

  @Attr() onlineTier: boolean;

  @Attr() tvContent: boolean;

  @Attr() tvTier: boolean;

  @Attr() radioContent: boolean;

  @Attr() radioTier: boolean;

  @Attr() printContent: boolean;

  @Attr() printTier: boolean;

  @Attr() totalMentionsCount: number;

  @Attr() uniqueMentionsCount: number;

  @Attr() magazineContent: boolean;

  @Attr() magazineTier: boolean;

  @Attr() socialContent: boolean;

  @Attr() socialTier: boolean;

  @Attr() podcastContent: boolean;

  @Attr() podcastTier: boolean;

  @Attr() socialImpactThreshold: number;

  @Attr() socialOnlyVerified: boolean;

  @Attr() organisationName: string;

  @Attr() originalId: number;

  @Attr() parentName: string;

  @Attr() queryType: InsightQueryType;

  @Attr() selectedTvContent: string[];

  @Attr() selectedRadioContent: RadioClip[];

  @Attr() selectedOnlineContent: Article[];

  @Attr() selectedPrintContent: string[];

  @Attr() selectedMagazineContent: any[];

  @Attr() selectedSocialContent: any[];

  @Attr() selectedPodcastContent: any[];

  @Attr() totalRatedMentions: number;

  @Attr() totalMentionsInReview: number;

  @HasMany() insightsQueryFilters: InsightsQueryFilter[];

  @HasMany() keySpokespeople: InsightsKeySpokesperson[];

  @Attr() internationalPlus: boolean;

  @Attr() includeOnlineCaldSources: boolean;

  @Attr() includePrintCaldSources: boolean;

  @Attr({ persist: false }) indexing: boolean;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) enabledMedia: string[];

  isProcessing() {
    return insightsQueryProcessingStatuses.includes(this.status);
  }

  isCompleted() {
    return ["export_completed", "sentiment_rating_completed"].includes(
      this.status
    );
  }
}

export default InsightsQuery;
