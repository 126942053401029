import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import InsightsEntity from "./InsightsEntity";
import InsightsQualitativeTopic from "./InsightsQualitativeTopic";
import User from "./User";

type InsightRatingType = "sentiment" | "exclusion" | "mention" | "topics";
type InsightReportType = "qualitative" | "insights_plus";

@Model()
class InsightsRating extends ApplicationRecord {
  static jsonapiType = "insights_ratings";

  @BelongsTo() insightsEntity: InsightsEntity;

  @BelongsTo() insightsTopic: InsightsQualitativeTopic;

  @BelongsTo() user: User;

  @HasMany() topicRatings: InsightsRating[];

  @Attr() parentId: number;

  @Attr() insightsEntityId: number;

  @Attr() insightsQueryId: number;

  @Attr() insightsTopicId: number;

  @Attr() qualitativeKeyMessageIds: number[];

  @Attr() excluded: boolean;

  @Attr() organisationId: number;

  @Attr() notes: string;

  @Attr() keywordCount: number;

  @Attr() ratingType: InsightRatingType;

  @Attr() reportType: InsightReportType;

  @Attr() sentiment: number;

  @Attr() influence: number;

  @Attr() subTopic: string;

  @Attr() topic: string;

  @Attr() mentionId: number;

  @Attr() mentionType: string;

  @Attr() mentionKey: string;

  @Attr() mentionPublishedAt: string;

  @Attr() needsReview: boolean;

  @Attr() rateableType: string;

  @Attr() rateableId: number;

  @Attr() reviewedAt: string;

  @Attr() userId: number;
}

export default InsightsRating;
