import { Attr, BelongsTo, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import QualityControlRule from "./QualityControlRule";
import QualityControlTrigger from "./QualityControlTrigger";
import User from "./User";

@Model()
class ContentVersion extends ApplicationRecord {
  static jsonapiType = "content_versions";

  @BelongsTo() user: User;

  @BelongsTo() item: QualityControlRule | QualityControlTrigger;

  @Attr() event: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr() objectChanges: Record<string, any>;

  @Attr() object: Record<string, any>;

  @Attr() itemId: number;

  @Attr() itemType: string;

  @Attr() feature: string;
}

export default ContentVersion;
