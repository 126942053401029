import { typecastRouteProps } from "shared/helpers/routing";

export default [
  {
    name: "online-search",
    path: "/articles",
    component: () => import("pages/OnlineArticleList.vue"),
    meta: {
      title: "Online Articles",
    },
  },
  {
    name: "articles-bulk-search",
    path: "/articles/bulk-search",
    component: () => import("pages/OnlineArticleBulkSearch.vue"),
    meta: {
      title: "Online Article Bulk Search",
    },
  },
  {
    name: "online-articles-add",
    path: "/articles/new",
    component: () => import("pages/OnlineArticleEdit.vue"),
    props: { medium: "websites" },
    meta: {
      title: "New Online Article",
    },
  },
  {
    name: "online-articles-edit",
    path: "/articles/:id",
    component: () => import("pages/OnlineArticleEdit.vue"),
    props: true,
    meta: {
      title: "Edit Online Article",
    },
  },
  {
    name: "websites-index",
    path: "/websites",
    component: () => import("pages/WebsiteList.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "Websites",
    },
  },
  {
    name: "websites-new",
    path: "/websites/new",
    component: () => import("pages/WebsiteEdit.vue"),
    meta: {
      title: "New Website",
    },
  },
  {
    name: "websites-edit",
    path: "/websites/:id/edit",
    component: () => import("pages/WebsiteEdit.vue"),
    props: true,
    meta: {
      title: "Edit Website",
    },
  },
  {
    name: "websites-scraper-logs",
    path: "/websites/scraper-logs",
    component: () => import("pages/WebsiteEventsList.vue"),
    props: (route) => typecastRouteProps(route),
    meta: {
      title: "Websites Scraper Logs",
    },
  },
  {
    name: "blocklist-url-patterns-index",
    path: "/blocklist-url-patterns",
    component: () => import("pages/BlocklistUrlPatternList.vue"),
    meta: {
      title: "Blocklist URL Patterns",
    },
  },
  {
    name: "blocklist-url-patterns-new",
    path: "/blocklist-url-patterns/new",
    component: () => import("pages/BlocklistUrlPatternEdit.vue"),
    meta: {
      title: "New Blocklist URL Pattern",
    },
  },
  {
    name: "blocklist-url-patterns-edit",
    path: "/blocklist-url-patterns/:id/edit",
    props: (route) => typecastRouteProps(route),
    component: () => import("pages/BlocklistUrlPatternEdit.vue"),
    meta: {
      title: "Edit Blocklist URL Pattern",
    },
  },
];
