/* eslint-disable import/no-mutable-exports */
import type { DebouncedFunc } from "lodash-es";
import { debounce } from "lodash-es";
import { Notify } from "quasar";
import type { ResponseError } from "spraypaint/lib-esm/request";
import { type Router } from "vue-router";

import { getLocaleText } from "shared/boot/i18n";
import type { CreateAxiosInstanceError } from "shared/helpers/axios";
import LoadError from "shared/pages/LoadError.vue";
import type { useUserStore } from "shared/stores/user";

type ErrorHandlerError = CreateAxiosInstanceError | ResponseError;
type DebouncedErrorHandler = DebouncedFunc<
  (
    error: ErrorHandlerError
  ) => Promise<ErrorHandlerError> | ErrorHandlerError | undefined
>;

function isCreateAxiosInstanceError(
  error: CreateAxiosInstanceError | ResponseError
): error is CreateAxiosInstanceError {
  return (error as CreateAxiosInstanceError).response?.data !== undefined;
}

const ONE_SECOND = 1000;

const showLoadErrorPage = (error: Error) => ({
  ...LoadError,
  error,
});

const initialErrorHandler: DebouncedErrorHandler = debounce(
  (error: ErrorHandlerError) => Promise.reject(error)
);

let unauthorizedError = initialErrorHandler;
let connectionError = initialErrorHandler;
let forbiddenError = initialErrorHandler;
let serverError = initialErrorHandler;

export default ({
  router,
  userStore,
}: {
  router: Router;
  userStore: ReturnType<typeof useUserStore>;
}) => {
  unauthorizedError = debounce(async (error: ErrorHandlerError) => {
    // Received 401, logout the user
    await userStore.logout();

    if (isCreateAxiosInstanceError(error)) {
      userStore.unauthorized(error.response!.data.message);
    }

    if (router.currentRoute.value.path !== "/sign-in") {
      router.replace({
        path: "/sign-in",
        query: {
          redirect: router.currentRoute.value.fullPath,
        },
      });
    }

    return error;
  }, ONE_SECOND);

  connectionError = debounce((error: ErrorHandlerError) => {
    Notify.create({
      message: getLocaleText("helpers.errors.connection_error_message"),
      type: "negative",
      icon: "wifi",
      caption: getLocaleText("helpers.errors.connection_error_detail"),
      position: "top",
      actions: [
        {
          label: getLocaleText("global.retry"),
          icon: "sync",
          noDismiss: true,
          handler: () => router.go(0),
        },
      ],
    });

    return error;
  }, ONE_SECOND);

  forbiddenError = debounce((error: ErrorHandlerError) => {
    Notify.create({
      message: getLocaleText("helpers.errors.forbidden_message"),
      type: "negative",
      icon: "lock",
      position: "top",
    });

    return error;
  }, ONE_SECOND);

  serverError = debounce((error: ErrorHandlerError) => {
    Notify.create({
      message: getLocaleText("helpers.errors.server_error_message"),
      type: "negative",
      icon: "error",
      caption: getLocaleText("helpers.errors.server_error_detail"),
      position: "top",
      actions: [
        {
          label: getLocaleText("global.retry"),
          icon: "sync",
          noDismiss: true,
          handler: () => router.go(0),
        },
      ],
    });

    return error;
  }, ONE_SECOND);
};

export {
  connectionError,
  forbiddenError,
  serverError,
  showLoadErrorPage,
  unauthorizedError,
};
