import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import { ApplicationRecord } from "shared/services/spraypaint";

import ContentVersion from "./ContentVersion";
import JournalistEnquiryRecipient from "./JournalistEnquiryRecipient";
import TwitterUser from "./TwitterUser";

@Model()
class Journalist extends ApplicationRecord {
  static jsonapiType = "journalists";

  @BelongsTo() twitterUser: TwitterUser;

  @HasMany() versions: ContentVersion[];

  @HasMany() journalistEnquiryRecipients: JournalistEnquiryRecipient[];

  @Attr() name: string;

  @Attr() avatarUrl: string;

  @Attr() email: string;

  @Attr() phone: string;

  @Attr() twitterUserId: string;

  @Attr() bio: string;

  @Attr() url: string;

  @Attr() urlHash: string;

  @Attr() facebookUrl: string;

  @Attr() linkedinUrl: string;

  @Attr() twitterUrl: string;

  @Attr() lastScraped: string;

  @Attr() firstName: string;

  @Attr() lastName: string;

  @Attr() instagramHandle: string;

  @Attr() visible: boolean;

  @Attr() hiddenReason: string;

  @Attr() notes: string;

  @Attr() alternateNames: string[];

  @Attr() secondaryEmails: string[];

  @Attr() alternateEmails: string[];

  @Attr() reviewedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  // extra attributes
  @Attr() primaryJobTitle: string;

  @Attr() primarySourceName: string;

  @Attr() twitterUsername: string;

  @Attr() lastUpdatedBy: string;

  @Attr() secondarySourceNames: string[];

  @Attr() publisherGroups: string[];
}

export default Journalist;
