/* eslint-disable import/prefer-default-export */

import { MiddlewareStack } from "spraypaint";

import {
  connectionError,
  forbiddenError,
  unauthorizedError,
} from "shared/boot/errors";
import ensureTokenValidity from "shared/services/tokenService";

export const middlewareStack = new MiddlewareStack();

middlewareStack.beforeFilters.push(async (_, options) => {
  const token = await ensureTokenValidity();

  // @ts-expect-error TODO: refactor to use Headers class
  Object.assign(options.headers, { Authorization: `Bearer ${token}` });
});

middlewareStack.afterFilters.push((response, json: any) => {
  if (response.status === 422 && json?.errors) return;

  if (
    !json ||
    json?.errors?.length ||
    json.error === true ||
    !response.ok ||
    !response.status
  ) {
    const error = new Error("abort") as any;
    error.response = Object.assign(response, { data: json });

    if (typeof json.error === "string") {
      error.response.data.message = json.error;
    }

    switch (response.status) {
      case null:
      case undefined:
        connectionError(error);
        break;
      case 401:
        unauthorizedError(error);
        break;
      case 403:
        forbiddenError(error);
        break;
      default:
    }

    throw error;
  }
});
