import { isNavigationFailure, NavigationFailureType } from "vue-router";

import { appInstance } from "shared/boot/app";

const THEME = import.meta.env.THEME || "default";

export const routingComponentErrorHandler = (to, from, next) => {
  const loadErrorComponent = to.matched.find(
    (match) => match.components?.default?.name === "LoadError"
  );

  if (loadErrorComponent) {
    const hasReloaded = Boolean(localStorage.getItem("appHasReloaded"));

    if (hasReloaded) {
      const { error } = loadErrorComponent.components.default;

      if (appInstance.config.globalProperties.$rollbar) {
        appInstance.config.globalProperties.$rollbar.error(error);
      }

      return next();
    }

    localStorage.setItem("appHasReloaded", 1);
    window.location.href = loadErrorComponent.path;

    return false;
  }

  return next();
};

function navigationFailureHandler(error) {
  if (
    !isNavigationFailure(error, NavigationFailureType.duplicated) &&
    !isNavigationFailure(error, NavigationFailureType.redirected) &&
    !isNavigationFailure(error, NavigationFailureType.cancelled) &&
    !isNavigationFailure(error, NavigationFailureType.aborted)
  ) {
    throw error;
  }
}

export function typecastRouteProps(route) {
  const routeData = { ...route.params, ...route.query };

  const component = [...route.matched]
    .reverse()
    .find((matchedRoute) => route.name === matchedRoute.name)
    .components.default;

  if (Object.keys(component.props).length === 0) return routeData;

  Object.entries(routeData).forEach(([key, value]) => {
    if (component.props[key]) {
      const [type] = [].concat(component.props[key].type);

      let processedValue = null;

      if (type === Array) {
        routeData[key] = value;
      } else if (type === Date) {
        routeData[key] = new Date(value);
      } else {
        try {
          processedValue = JSON.parse(value);
        } catch {
          processedValue = value;
        }

        routeData[key] = type(processedValue);
      }
    }
  });

  return routeData;
}

export const applyThemeFromRoute = (to, from) => {
  const oldProductRoute = Array.from(from.matched)
    .reverse()
    .find((route) => route.meta.product);

  const newProductRoute = Array.from(to.matched)
    .reverse()
    .find((route) => route.meta.product);

  const oldProduct = from.params.product || oldProductRoute?.meta?.product;
  const newProduct = to.params.product || newProductRoute?.meta?.product;

  if (oldProduct && oldProduct !== newProduct) {
    document.documentElement.classList.remove(`${THEME}-${oldProduct}`);
  }

  if (newProduct && newProduct !== oldProduct) {
    document.documentElement.classList.add(`${THEME}-${newProduct}`);
  }
};

export const safePush =
  ({ router }) =>
  (...args) =>
    router.push(...args).catch(navigationFailureHandler);
export const safeReplace =
  ({ router }) =>
  (...args) =>
    router.replace(...args).catch(navigationFailureHandler);

export default routingComponentErrorHandler;
