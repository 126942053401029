<template>
  <QBtn
    v-if="showPrevious"
    :class="{ 'tw-cursor-default tw-opacity-30': isPreviousDisabled }"
    v-bind="buttonDefaults('previous_item_in_queue')"
    icon="ion-ios-skip-backward"
    @click="canSkipPrevious && emit('skip-previous')"
  >
    <StreemTooltip>{{
      getLocaleText("player_popout_buttons.previous_item_in_playlist")
    }}</StreemTooltip>
  </QBtn>

  <QBtn
    v-bind="buttonDefaults('thirty_seconds_back')"
    :disable="firstPlay || disabled"
    :icon="isPopoutPoppingOut ? 'ion-ios-rewind' : 'replay_30'"
    @click="emit('rewind')"
  >
    <StreemTooltip>{{
      getLocaleText("player_popout_buttons.thirty_seconds_back")
    }}</StreemTooltip>
  </QBtn>

  <QBtn
    class="tw-self-stretch"
    :disable="loading || disabled"
    :icon="isPlaying ? 'ion-ios-pause' : 'ion-ios-play'"
    v-bind="buttonDefaults('play_or_pause')"
    @click="emit('toggle-play')"
  >
    <StreemTooltip>{{
      isPlaying
        ? getLocaleText("player_popout_buttons.pause")
        : getLocaleText("player_popout_buttons.play")
    }}</StreemTooltip>
  </QBtn>

  <QBtn
    v-bind="buttonDefaults('thirty_seconds_forward')"
    :disable="firstPlay || disabled"
    :icon="isPopoutPoppingOut ? 'ion-ios-fastforward' : 'forward_30'"
    @click="emit('fastforward')"
  >
    <StreemTooltip>{{
      getLocaleText("player_popout_buttons.thirty_seconds_forward")
    }}</StreemTooltip>
  </QBtn>

  <QBtn
    v-if="showNext"
    :class="{ 'tw-cursor-default tw-opacity-30': isNextDisabled }"
    v-bind="buttonDefaults('next_item_in_queue')"
    icon="ion-ios-skip-forward"
    @click="canSkipNext && emit('skip-next')"
  >
    <StreemTooltip>{{
      getLocaleText("player_popout_buttons.next_item_in_playlist")
    }}</StreemTooltip>
  </QBtn>

  <QBtn
    v-if="showExpandButton"
    v-bind="buttonDefaults('expand_player')"
    icon="ion-expand"
    @click="setPlayerExpanded(true)"
  >
    <StreemTooltip>{{
      getLocaleText("player_popout_buttons.expand_player")
    }}</StreemTooltip>
  </QBtn>

  <QBtn
    v-if="showShrinkButton"
    icon="ion-contract"
    v-bind="buttonDefaults('shrink_player')"
    @click="setPlayerExpanded(false)"
  >
    <StreemTooltip>{{
      getLocaleText("player_popout_buttons.shrink_player")
    }}</StreemTooltip>
  </QBtn>
</template>

<script lang="ts">
export interface PlayerPopoutButtonsProps {
  disabled?: boolean;
  isPlaying?: boolean;
  firstPlay?: boolean;
  loading?: boolean;
  showPrevious?: boolean;
  noPreviousClip?: boolean;
  showNext?: boolean;
  noNextClip?: boolean;
  isAudio?: boolean;
}
</script>

<script setup lang="ts">
import { kebabCase } from "lodash-es";
import { storeToRefs } from "pinia";
import { computed, inject } from "vue";

import { getLocaleText } from "shared/boot/i18n";
import { StreemTooltip } from "shared/components/base";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

// Rendering fragments - we can't accept overloads
defineOptions({ inheritAttrs: false });

const props = defineProps<PlayerPopoutButtonsProps>();

const emit = defineEmits<{
  (e: "skip-previous"): void;
  (e: "rewind"): void;
  (e: "toggle-play"): void;
  (e: "fastforward"): void;
  (e: "skip-next"): void;
}>();

const isDesktop = inject("isDesktop", false);

const universalPlayerStore = useUniversalPlayerStore();
const { setPlayerExpanded } = universalPlayerStore;

const { isPopoutPoppingOut, playerIsExpanded } =
  storeToRefs(universalPlayerStore);

const buttonDefaults = (name: string) => ({
  "aria-label": getLocaleText(`player_popout_buttons.${name}`),
  flat: true,
  padding: isPopoutPoppingOut.value ? "sm 12px" : "0",
  size: isPopoutPoppingOut.value ? undefined : "18px",
  style: `grid-area: ${kebabCase(name)}`,
});

const isPreviousDisabled = computed(
  () =>
    props.noPreviousClip ||
    props.disabled ||
    !isDesktop ||
    !isPopoutPoppingOut.value
);

const canSkipPrevious = computed(
  () =>
    !props.noPreviousClip &&
    !props.disabled &&
    isDesktop &&
    isPopoutPoppingOut.value
);

const isNextDisabled = computed(
  () =>
    props.noNextClip ||
    props.disabled ||
    !isDesktop ||
    !isPopoutPoppingOut.value
);

const canSkipNext = computed(
  () =>
    !props.noNextClip &&
    !props.disabled &&
    isDesktop &&
    isPopoutPoppingOut.value
);

const isPoppedOutVideo = computed(
  () => isPopoutPoppingOut.value && !props.isAudio
);

const showExpandButton = computed(
  () => !playerIsExpanded.value && isPoppedOutVideo.value
);

const showShrinkButton = computed(
  () => playerIsExpanded.value && isPoppedOutVideo.value
);
</script>
