<template>
  <div class="player-popout">
    <div ref="videoContainerHidden">
      <video
        ref="popoutVideo"
        class="popout-video"
        :class="{
          'popout-audio': isAudio,
          'popout-video-playlist': showPlayList,
        }"
        playsinline
        :src="audioSource"
        webkit-playsinline
        @ended="ended"
        @pause="paused"
        @play="played"
      />
    </div>
    <template v-if="playerRef">
      <Portal
        v-if="isLoaded"
        :to="playerPopoutTarget"
      >
        <PlayerPopoutInterface
          v-model:current-time="currentTime"
          :clip="initialClip"
          :clip-list-index="clipListIndex"
          :disabled="modalOpen"
          :first-play="firstPlay"
          :initial-clip="initialClip"
          :is-audio="isAudio"
          :is-playing="playerIsPlaying"
          :loading="loading"
          :no-next-clip="noNextClipInList"
          :no-previous-clip="noPreviousClipInList"
          :played-clip="playedClip"
          :player="playerRef"
          :show-restriction-message="showRestrictionMessage"
          :time-zone="initialClip.source.time_zone"
          @clear-list="clearList"
          @close="close"
          @fastforward="fastforward"
          @mount-video="mountVideo"
          @play-item="playNow($event)"
          @remove-item="removeItem($event)"
          @rewind="rewind"
          @seek="updateClocks"
          @skip-next="nextClipInList"
          @skip-previous="previousClipInList"
          @toggle-play="togglePlay"
          @toggle-playlist="togglePlayList"
        />
      </Portal>
    </template>
  </div>
</template>

<script>
import { merge } from "lodash-es";
import { storeToRefs } from "pinia";
import { Portal } from "portal-vue";

import PlayerPopoutInterface from "shared/components/players/PlayerPopoutInterface.vue";
import { PodcastEpisode } from "shared/resources";
import { addShortcutList, removeShortcutList } from "shared/services/shortcuts";
import { useGlobalStore } from "shared/stores/global";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

const BLANK_CLIP = {
  media_url: "",
  source: {},
};

export default {
  name: "PlayerPopout",
  components: {
    Portal,
    PlayerPopoutInterface,
  },
  emits: ["close"],
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();

    const {
      activeControl,
      playerClip,
      playerInitialClip,
      playerIsPlaying,
      playerList,
      playerOpen,
      playerPopoutTarget,
      popoutVideoRef,
      universalPlayerRef,
    } = storeToRefs(universalPlayerStore);

    const {
      isPopoutPoppingOut,
      playerClearList,
      playerHide,
      playerRemoveListItem,
      playerSetInitialClip,
      playerSetRef,
      playerUpdateClip,
      setPlayerDismissed,
      setPlayerExpanded,
      setPlayerPlaying,
      setPlayerPopoutActiveControl,
      setPlayerPopoutRef,
      setPopoutVideoRef,
    } = universalPlayerStore;

    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    return {
      universalPlayerRef,
      playerClip,
      playerOpen,
      playerList,
      playerInitialClip,
      playerIsPlaying,
      playerPopoutTarget,
      popoutVideoRef,
      isPlayerFloating: isPopoutPoppingOut,
      activeControl,

      playerSetInitialClip,
      playerUpdateClip,
      playerSetRef,
      playerClearList,
      playerRemoveListItem,
      setPlayerPlaying,
      setPlayerPopoutRef,
      playerHide,
      setPopoutVideoRef,
      setPlayerPopoutActiveControl,
      setPlayerDismissed,
      setPlayerExpanded,

      appConfig,
    };
  },
  data() {
    return {
      isLoaded: false,
      firstPlay: true,
      loading: false,
      interval: null,
      currentTime: 0,
      modalOpen: false,
      showRestrictionMessage: false,
      shortcuts: [
        {
          key: " ",
          callback: this.togglePlay,
        },
      ],
    };
  },
  computed: {
    playedClip: {
      get() {
        return this.playerClip || this.initialClip;
      },
      set(clip) {
        this.playerUpdateClip(clip);
      },
    },
    playerRef() {
      return this.universalPlayerRef;
    },
    hasClip() {
      return Boolean(this.playedClip.id);
    },
    initialClip() {
      return this.playerInitialClip || BLANK_CLIP;
    },
    mention() {
      return this.initialClip;
    },
    showPlayList() {
      return this.activeControl === "playList";
    },
    hasNextClip() {
      return "next" in this.playedClip;
    },
    hasPreviousClip() {
      return "previous" in this.playedClip;
    },
    clipListIndex() {
      return this.playerList.findIndex(
        (item) => item.id === this.initialClip.id
      );
    },
    noNextClipInList() {
      return (
        this.playerList.length <= 1 ||
        this.clipListIndex < 0 ||
        this.clipListIndex === this.playerList.length - 1
      );
    },
    noPreviousClipInList() {
      return this.playerList.length <= 1 || this.clipListIndex <= 0;
    },
    audioSource() {
      return this.playedClip
        ? this.playedClip.signed_url || this.playedClip.media_url
        : null;
    },
    unableToPlay() {
      return !this.hasClip || this.modalOpen || !this.playerOpen;
    },
    isAudio() {
      return ["radio_clip", "podcast_episode"].includes(this.mention.type);
    },
    restrictAdditionalPlayback() {
      if (this.appConfig.platformName === "CisionOne") return false;

      if (this.isAudio) return false;
      if (this.adminUserEnabled) return false;

      if (
        this.playedClip.program_airing &&
        this.playedClip.program_airing.current_affairs
      ) {
        return false;
      }

      return true;
    },
  },
  mounted() {
    this.isLoaded = true;
    this.playerSetRef(this.$refs.popoutVideo);
    this.setPlayerPopoutRef(this);
    this.setPopoutVideoRef(this.$refs.popoutVideo);
    addShortcutList(this.shortcuts);
    if (this.playerIsPlaying) this.play();
  },
  beforeUnmount() {
    removeShortcutList(this.shortcuts);
  },
  methods: {
    // Player Controls
    async togglePlay(force = false) {
      if (force) this.modalOpen = false;
      if (this.unableToPlay) return;

      if (!force && this.playerIsPlaying) {
        this.pause();
      } else if (this.firstPlay) {
        this.loading = true;
        await this.updateClipInformation();
        this.play();
        this.loading = false;
        this.firstPlay = false;
        this.trackAction("Played a clip from popout player");
      } else {
        this.firstPlay = false;
        await this.updateClipInformation();
        this.play();
        this.trackAction("Played a clip from popout player");
      }
    },
    previousClipInList() {
      const prevClip = this.playerList[this.clipListIndex - 1];
      this.playerSetInitialClip(prevClip);
      if (this.isAudio) this.setPlayerExpanded(false);
      this.continuePlaying();
      this.trackAction("Played previous clip in popout player", prevClip);
    },
    nextClipInList() {
      const nextClip = this.playerList[this.clipListIndex + 1];
      this.playerSetInitialClip(nextClip);
      if (this.isAudio) this.setPlayerExpanded(false);
      this.continuePlaying();
      this.trackAction("Played next clip in popout player", nextClip);
    },
    continuePlaying(force = false) {
      if (this.playerIsPlaying || force)
        this.$nextTick(() => {
          this.play();
        });
    },
    rewind() {
      // If we should play the previous clip 30 seconds before
      if (this.hasPreviousClip && this.playerRef.currentTime - 30 < 0) {
        this.previousClip();
      } else {
        this.playerRef.currentTime -= 30;
      }

      this.$nextTick().then(() => this.updateClocks());
      this.trackAction("Rewound a clip from popout player");
    },
    fastforward() {
      // If the clip will be finished 30 seconds later
      if (this.playerRef.currentTime + 30 > this.playerRef.duration) {
        if (this.hasNextClip) {
          this.nextClip();
        } else {
          this.resetPlayer();
        }
      } else {
        this.playerRef.currentTime += 30;
      }

      this.$nextTick().then(() => this.updateClocks());
      this.trackAction("Fastforwarded a clip from popout player");
    },
    nextClip() {
      if (!this.hasNextClip) return;

      if (
        this.restrictAdditionalPlayback &&
        this.playedClip.previous.id === this.initialClip.id
      ) {
        this.showCurrentAffairsMessage();

        return;
      }

      this.playedClip = merge({}, this.playedClip, {
        next: {
          previous: this.playedClip,
          end_time: this.playedClip.next.start_time + 30,
          origin: {
            ...this.playedClip.origin,
          },
        },
      });

      this.playerRef.currentTime = 0;
      this.playedClip = this.playedClip.next;

      this.updateClipInformation();
      this.continuePlaying(true);
    },
    play() {
      if (this.showRestrictionMessage) {
        this.showRestrictionMessage = false;

        this.$nextTick().then(() => this.play());

        return;
      }

      const playPromise = this.playerRef.play();

      if (playPromise.constructor === Promise) {
        // Handle errors in the play promise
        playPromise.catch((error) => {
          if (this.$rollbar) this.$rollbar.error(error);
        });
      }
    },
    pause() {
      this.playerRef.pause();
      this.setPlayerPlaying(false);
      this.trackAction("Paused a clip from popout player");
    },
    previousClip() {
      if (!this.hasPreviousClip) return;

      if (
        this.restrictAdditionalPlayback &&
        this.hasNextClip &&
        this.playedClip.next.id === this.initialClip.id
      ) {
        this.showCurrentAffairsMessage();

        return;
      }

      this.playedClip = merge({}, this.playedClip, {
        previous: {
          next: this.playedClip,
          end_time: this.playedClip.previous.start_time + 30,
          origin: {
            ...this.playedClip.origin,
          },
        },
      });

      this.playerRef.currentTime = 0;
      this.playedClip = this.playedClip.previous;

      this.updateClipInformation();
      this.continuePlaying(true);
    },
    resetPlayer() {
      // Reset the player to the begin of the first clip
      this.pause();
      this.playerRef.currentTime = 0;

      // If we fetched data, we can't use clip prop because we would lose all fetched data
      this.playedClip = this.initialClip;
    },

    // Player Events
    played() {
      this.setPlayerPlaying(true);
      clearInterval(this.interval);
      this.interval = setInterval(this.updateClocks, 500);
    },
    paused() {
      this.setPlayerPlaying(false);
      clearInterval(this.interval);
    },
    // Playlist Controls
    togglePlayList() {
      const activeControl = this.showPlayList ? null : "playList";
      this.setPlayerPopoutActiveControl(activeControl);
    },
    playNow(clip) {
      if (this.initialClip.id !== clip.id) this.playerSetInitialClip(clip);
      if (this.isAudio) this.setPlayerExpanded(false);

      this.$nextTick(() => {
        this.togglePlay(true);
      });
    },
    clearList() {
      this.playerClearList();
      this.removeItem(this.initialClip);
      this.trackAction("Cleared the popout player playlist", this.initialClip);
    },
    removeItem(clip) {
      if (clip.id === this.initialClip.id) {
        if (this.noNextClipInList) this.pause();
        this.nextClipInList();
      }

      this.playerRemoveListItem(clip);
      this.trackAction("Removed clip from popout player playlist", clip);
    },
    updateClocks() {
      if (!this.playerRef) return;

      this.currentTime = this.playerRef.currentTime;
    },
    async updateClipInformation(retry = 0) {
      if (this.playedClip.fetched) {
        return;
      }

      try {
        if (this.mention.type === "podcast_episode") {
          const episode = (
            await PodcastEpisode.selectExtra(["signedUrl"])
              .select(["signedUrl"])
              .find(this.mention.id)
          ).data;

          this.playedClip = {
            ...this.playedClip,
            signed_url: episode.signedUrl,
          };

          this.playedClip.fetched = true;
        } else {
          const response = await this.$streemApiV1.get(
            `${this.mention.type === "radio_clip" ? "radio" : "tv"}_clips/${
              this.playedClip.id
            }`
          );

          this.playedClip = {
            ...response.data,
            ...this.playedClip,
            fetched: true,
          };

          if (this.hasNextClip && !this.playedClip.next.cachedUrl) {
            await this.cacheNextClip();
          }
        }
      } catch {
        // retry with exponential backoff, max 4 attempts.
        if (retry < 4) {
          const retryTimeout = 1000 * 2 ** retry;
          setTimeout(this.updateClipInformation, retryTimeout, retry + 1);
        }
      }
    },
    close() {
      this.pause();
      this.setPlayerPopoutActiveControl(null);
      this.setPlayerDismissed(true);
      this.$emit("close");
    },
    mountVideo() {
      // Only move the player back to the hidden div if it's no longer playing.
      if (!this.playerIsPlaying && !this.popoutVideoRef.paused) {
        this.$refs.videoContainerHidden.appendChild(this.popoutVideoRef);
      }
    },
    trackAction(message, clip = null) {
      let currentClip = clip;

      if (!currentClip && this.playerList.length) {
        currentClip = this.playerList[this.clipListIndex];
      } else {
        currentClip = this.initialClip;
      }

      let type = "";

      switch (currentClip.type) {
        case "podcast_episode":
          type = "podcast";
          break;
        case "radio_clip":
          type = "radio";
          break;
        default:
          type = "tv";
      }

      // Strip out the next and previous objects,
      // which have a circular reference to the currentClip.
      const { next, previous, ...clipToTrack } = currentClip;

      this.$track(message, {
        clip: clipToTrack,
        type,
      });
    },
    showCurrentAffairsMessage() {
      this.pause();
      this.showRestrictionMessage = true;
    },
    ended() {
      if (this.firstPlay) {
        this.$track("Popout player - Playing clip ended prematurely");
        this.setPlayerPlaying(false);

        return;
      }

      if (this.hasNextClip) {
        this.$track("Popout player - Playing next clip");
        this.nextClip(true);
      } else {
        this.$track("Popout player - Clip ended - no next clip");
        this.resetPlayer();
      }

      this.$nextTick().then(() => this.updateClocks());
    },
    async cacheNextClip() {
      // If there is already a request to cache a clip, cancel it
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }

      this.cancelSource = this.$cacheFetch.cancelToken.source();

      const url =
        this.playedClip.next.signed_url || this.playedClip.next.media_url;

      try {
        const response = await this.$cacheFetch.get(url, {
          responseType: "arraybuffer",
          cancelToken: this.cancelSource.token,
          noAuth: true,
        });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });

        this.playedClip = {
          ...this.playedClip,
          next: {
            ...this.playedClip.next,
            cachedUrl: window.URL.createObjectURL(blob),
          },
        };
      } catch (error) {
        if (!this.$cacheFetch.isCancel(error)) throw error;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popout-video {
  display: block;
  width: 100%;
  height: auto;
}

.popout-video.popout-audio {
  height: 0;
}
</style>
