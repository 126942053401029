<template>
  <Component
    :is="to || type === 'a' ? 'a' : 'button'"
    :aria-label="ariaLabel"
    :class="[defaultClasses, buttonClasses, textClass, borderClass]"
    :disabled="disabled || undefined"
    :href="hrefLink"
    :target="target"
    :type="type"
    @click="handleClick"
    @mouseenter="emit('mouseenter', $event)"
    @mouseleave="emit('mouseleave', $event)"
  >
    <template v-if="showIcon">
      <span
        v-if="newDesign"
        class="base-button__icon"
      >
        <BaseIcon
          v-if="newIcon"
          :icon="icon"
          :icon-class="iconClass"
        />
        <QIcon
          v-else
          :class="iconClass"
          :name="icon"
        />
      </span>
      <QIcon
        v-else
        :name="icon"
        :size="computedIconSize"
      />
    </template>

    <slot>
      <span
        v-if="hasLabel"
        :class="[labelClass, icon && !newDesign && 'q-pl-xs']"
      >
        {{ label }}
      </span>
    </slot>

    <slot name="loading">
      <QSpinner v-if="loading" />
    </slot>

    <slot name="after" />
  </Component>
</template>

<script setup lang="ts">
import chroma from "chroma-js";
import { type AriaAttributes, computed, useAttrs } from "vue";
import { type RouteLocationRaw, useRouter } from "vue-router";

import BaseIcon from "shared/components/base/BaseIcon.vue";
import { lookupColor } from "shared/helpers/colors";
import { safePush } from "shared/helpers/routing";

export type ButtonSize = "sm" | "md";
type ButtonType = HTMLButtonElement["type"] | "a";

export interface Props {
  actionItem?: boolean;
  active?: boolean;
  activeColor?: string;
  bordered?: boolean;
  circle?: boolean;
  color?: string;
  dense?: boolean;
  disabled?: boolean;
  flat?: boolean;
  href?: string | null;
  icon?: string;
  iconRight?: boolean;
  iconSize?: string;
  iconClass?: string;
  label?: string | number | null;
  labelClass?: string;
  loading?: boolean;
  menuItem?: boolean;
  newDesign?: boolean;
  newIcon?: boolean;
  padding?: string;
  plain?: boolean;
  size?: ButtonSize;
  square?: boolean;
  target?: string | null;
  to?: RouteLocationRaw | null;
  type?: ButtonType;
}

const props = withDefaults(defineProps<Props>(), {
  activeColor: "",
  color: "",
  href: null,
  icon: "",
  iconSize: "",
  iconClass: "",
  label: null,
  padding: "sm md",
  size: "md",
  target: null,
  to: null,
  type: "button",
  labelClass: "",
});

const emit = defineEmits<{
  click: [MouseEvent];
  mouseenter: [MouseEvent];
  mouseleave: [MouseEvent];
}>();

const attrs = useAttrs();

const defaultClasses = computed(() => [
  "base-button",
  "inline",
  "items-center",
  "justify-center",
  "row",
]);

const hasLabel = computed<boolean>(() =>
  Boolean(props.label !== null && props.label.toString().length)
);

const ariaLabel = computed<string | null>(() => {
  const label =
    (attrs["aria-label"] as AriaAttributes["aria-label"]) || props.label;

  return label ? String(label) : null;
});

const computedIconSize = computed<string>(() => {
  if (props.icon && !props.iconSize) {
    return props.newDesign ? "md" : "xs";
  }

  return props.iconSize;
});

const showIcon = computed<boolean>(() => {
  if (!props.icon) return false;

  if (props.loading && !hasLabel.value) return false;

  return true;
});

const router = useRouter();
const routerSafePush = safePush({ router });

const hrefLink = computed<string | null>(() => {
  if (props.disabled) return null;

  if (props.href) return props.href;

  if (props.to === null) return null;

  return router.resolve(props.to).href;
});

const colorName = computed<string>(() => {
  let { color } = props;

  if (props.active && props.activeColor) {
    color = props.activeColor;
  }

  return color || "white";
});

const buttonColor = computed<string>(() => lookupColor(props.color) || "#fff");
const contrast = computed<number>(() => chroma(buttonColor.value).get("lab.l"));

const paddingClasses = computed<string>(() => {
  if (props.newDesign) return "";

  const sizes = props.padding.split(" ");
  if (props.dense) return "dense";
  if (sizes.length === 1) return `q-pa-${sizes[0]}`;

  return `q-py-${sizes[0]} q-px-${sizes[1]}`;
});

const buttonClasses = computed<string[]>(() => {
  if (props.newDesign) {
    const classes = ["new-design"];

    classes.push(`base-button__${colorName.value}`);
    if (props.bordered) classes.push(`base-button__${colorName.value}-border`);

    classes.push(`base-button__${props.size}`);
    if (props.flat) classes.push("base-button__flat");
    if (props.dense) classes.push("base-button__dense");
    if (props.circle) classes.push("base-button__round");
    if (props.square) classes.push("base-button__square");
    if (props.disabled) classes.push("base-button__disable");
    if (computedIconSize.value)
      classes.push(`base-button__icon-${computedIconSize.value}`);
    if (props.iconRight) classes.push("base-button__icon-right");
    if (!hasLabel.value) classes.push("base-button__icon-only");
    if (props.menuItem) classes.push("base-button__menu-item");
    if (props.actionItem) classes.push("base-button__action-item");
    if (props.active && !props.activeColor) classes.push("base-button__active");
    if (props.plain) classes.push("base-button__plain");

    return classes;
  }

  const classes = [
    props.flat ? "flat" : `bg-${props.color}-important`,
    paddingClasses.value,
  ];

  if (props.circle) classes.push("circle");
  if (props.square) classes.push("square");
  if (props.disabled) classes.push("disable");

  return classes;
});

const textClass = computed<string>(() => {
  if (props.newDesign) return "";

  if (props.flat && props.color) return `text-${props.color}`;

  return contrast.value < 70 ? "text-white" : "text-primary";
});

const borderClass = computed<string>(() => {
  if (props.newDesign) return "";

  if (props.bordered) {
    if (props.flat) return `bordered border-color-${props.color}`;

    return contrast.value < 70
      ? "bordered border-color-white"
      : "bordered border-color-primary";
  }

  return "";
});

function handleClick(event: MouseEvent): void {
  if (props.disabled) return;

  if (props.to && !props.target) {
    event.preventDefault();
    routerSafePush(props.to).catch(() => {});
  }

  emit("click", event);
}
</script>

<style lang="scss" scoped>
button:not(.new-design),
a:not(.new-design) {
  border-radius: 4px;
  outline: none;
  border: none;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  box-shadow: 0 2px 0 rgba($black, 0.043);
  transition:
    background 150ms ease-in-out,
    transform 150ms ease;
  appearance: none;

  &.circle {
    border-radius: 50%;
  }

  &.square {
    border-radius: 0;
  }

  &.bordered {
    border: 1px solid;
  }

  &.dense {
    padding: 4px;
  }

  &:active:not([disabled]) {
    background: currentcolor;
    opacity: 0.9;
  }

  &.flat {
    box-shadow: none;

    &:active:not([disabled]) {
      background: rgba($secondary, 0.5);
    }
  }

  :deep(i) {
    line-height: 14px;
    font-size: 14px;
  }
}
</style>
