<template>
  <QTooltip
    ref="tooltip"
    v-bind="{ ...attrs, ...props }"
    :anchor="anchor"
    :class="streemContentClass"
    :self="self"
  >
    <div class="inner">
      <slot />
    </div>
  </QTooltip>
</template>

<script setup lang="ts">
import { QTooltip } from "quasar";
import { computed, ref, useAttrs } from "vue";

export interface Props {
  anchor?: QTooltip["anchor"];
  self?: QTooltip["self"];
  contentClass?: string;
  transitionShow?: QTooltip["transitionShow"];
  transitionHide?: QTooltip["transitionHide"];
  noCaret?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  anchor: "bottom middle",
  self: "top middle",
  contentClass: "",
  transitionShow: "jump-down",
  transitionHide: "jump-up",
});

const attrs = useAttrs();

const tooltip = ref<InstanceType<typeof QTooltip>>();

const caretClass = computed<string>(() => {
  if (props.noCaret) return "";

  const [caretY, caretX] = props.self.toLowerCase().split(" ");

  return `caret-${caretY}-${caretX}`;
});

const streemContentClass = computed<string[]>(() => [
  props.contentClass,
  "streem-tooltip",
  caretClass.value,
]);

function show(): void {
  tooltip.value?.show();
}

function hide(): void {
  tooltip.value?.hide();
}

defineExpose({
  show,
  hide,
});
</script>

<style lang="scss" scoped>
.inner {
  word-break: break-word;
  max-width: 300px;
}
</style>
